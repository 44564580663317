.pagination{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 1vw;
}

.companiesContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.editCompaniesButton{
    border: 0.15vw solid #00ABC8;
    background-color: white;
    border-radius: 2vw;
    color: #00ABC8;
    font-size: 0.8vw;
    font-weight: normal;
    width: 7vw;
    cursor: pointer;
    padding: 1.5%;
    margin: 2%;
}

.createCompanyButton{
    border: 0.15vw solid #00ABC8;
    background-color: white;
    border-radius: 1.5vw;
    color: #00ABC8;
    font-size: 1.1vw;
    font-weight: normal;
    width: auto;
    cursor: pointer;
    padding: 2%;
    margin-top: 2vw;
    margin-bottom: 1vw;
}

.searchQrLabel{
    margin-right: 0.5vw;
}

.searchQrInput{
    border-radius: 2vw;
    border-color: #D9F1F5;
    padding: 2%;
    font-family: 'Quattrocento Sans', sans-serif !important;
    font-size: 1vw;
    width: 90%;
}

.inputContainer {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1vw;
}

.dropdownButton{
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #00ABC8;
}


.deleteButton {
    border: 0.15vw solid #FF001F;
    background-color: white;
    border-radius: 2vw;
    color: #FF001F;
    font-size: 0.8vw;
    font-weight: normal;
    width: 7vw;
    cursor: pointer;
    padding: 1.5%;
    margin: 2%;
}

.tableContainer{
    width: 100%;
}

.buttonsContainer{
    display: flex;
    flex-direction: row;
}

.noCompanies{
    font-size: 1.6vw;
    color: #1B1464;
}