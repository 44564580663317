.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    position: relative;
    top: 5%;
}

.button {
   
    position: relative;
    background-color: var(--white);
    border:solid 0.1vw var(--cyan);
    border-radius: .5vw;
    /* font-size: 16px; */
    color: var(--cyan);
    font-size: 1vw;
    font-family: var(--font1);
    cursor: pointer;
    height: 2vw;
    width:7.5vw;
    align-items: center;
    justify-content: center;
    /* Safari */
}

