.container {
    border: solid blue 1px;
    width: 100%;
    height: 80%;
}

.buttonContainer{
    position: absolute;
    top: 2%;
    right: 6%;
    height: 8%;
    z-index: 1;
}

.chartContainer {
    display:flex;
    position: relative;
    padding-top: 1%;
    // border: solid blue 1px; 
    padding-right: 5%;
    /* left: -5%; */
    width: 85%;
    height: 100%;
    user-select: none;
}
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 70%;
  //  position: absolute;
   // border:solid  rgba(216, 24, 24, 0.5) 1px;
    z-index: 1;
}
.noData {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 70%;
    font-size: 2vw;
    color: #1B1464;
    z-index: 1;
}