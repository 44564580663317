.chartContainer{
    display : flex;
    height: 100%;
    /* border: solid red 1px; */
    padding-top: 2vw;
}
.loading{
  margin:auto;
  /* border: solid red 1px; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
    top: 50%;
}
.noData{
    margin-top: 2vw;
    margin-left: 43%;
    font-size:var(--h1);
    color: var(--blue);
}
