.container {
    display: flex;
    flex-direction: column;
    margin-top: 1vw;
    width: 88vw;
    height: 42vw;
    margin-bottom: 1vw;
   
}

.title_container {

    margin-left: 25vw;
    font-family: 'Quattrocento Sans', sans-serif;
    color: #1B1464;
    font-size: 2.5vw;

}

.map_and_info_container {
    display: flex;
    margin-top: 1vw;
    width: 88vw;
    height: 100vh;
    margin-bottom: 1vw;
    /* border: solid red 1px; */
    justify-content: space-between;

}
.buttonContainer{
   
    position :absolute;
    top:10vw;
    left:10vw


}
.mapContainer {
    display: flex;
    flex-direction: column;
    border: 0.15vw solid #00abc8;
    width: 75%;
    border-radius: 0.6vw;


}

.map {
    width: 100%;
    height: 100%;
}

.sideInfoContainer {
    padding: 1vw;
    border: 0.15vw solid #00abc8;
    width: 21%;
    border-radius: 0.6vw;
    background-color: #fafafa;

    overflow-y: auto;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30%;
    margin:auto;
}
.no_devices{
    display: flex;
    /* justify-content: center; */
    padding-left:20%;
    align-items: center;
    font-size: 3vw;
    font-family: 'Quattrocento Sans', sans-serif;
   
    margin: auto;
    width: 70%;
    height: 4vw;
    color: #1B1464;

}