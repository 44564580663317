.title{
    width: 42%;
  
    font-size: 1.3vw;
    color: #1B1464;
    margin-left: 25%;
    margin-bottom: 1.5vw;
    margin-top: 0.5vw;
}

.infoBox{
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
}

.imageAndTitle{
    width: 30%;
    margin-bottom: 0.8vw;
}

.firstLine {
    display: flex;
}
.titulo_grafico{
    display: flex;
    justify-content: space-between;
    align-items: center;
   // border: solid red 1px;
    width: 88%;
    font-size: var(--h4);
    font-weight: bold;
    // color: #1B1464;
    margin-left: 75px;
    margin-top: 1vw;
}
.graphContainer{
    margin-top: 0.5vw;
    width: 100%;
    height: 38vw;
}

.infoTitle{
    color: #00ABC8;
    font-size: 1vw;
}

.infoIDTitle{
    color: #00ABC8;
    font-size: 1vw;
}

.eachBox{
    display: flex;
    flex-direction: column;
    align-items: center;    
    margin-left: 1%;
    margin-right: 1%;
}

.info{
    font-size: 1vw;
    width: 100%;
    margin-left: 5%;
    margin-top: 0.2vw;
    color: #1B1464;
}

.icon{
    max-width: 5vw;
    max-height: 4vw;
    margin-bottom: -4vw;
}

.loading{
    margin-top: 20vw;
    margin-left: 47%;
}

.allContainer{
    width: 100%;
    height: 40vw;
    
}

.graphContainer{
    position: relative;
    top:-1vw;
    width: 100%;
    height: 30vw;
//  border: solid red 1px; 
 }

.noData{
    margin-top: 2vw;
    margin-left: 43%;
    font-size: 2vw;
    color: #1B1464;
}
.no_select {
    user-select: none; /* Previene la selección de texto */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    color:"red"
   }


/* @media (max-width:1370px){
    .infoBox{
        margin-left: 10px;
    }

    .infoTitle{
        font-size: 14px;
    }

    .infoIDTitle{
        font-size: 14px;
    }

    .info{
        font-size: 13px;
    }

    .eachBox{
        margin-right: 10px;
        margin-left: -5px;
    }

    .title{
        font-size: 15px;
        margin-bottom: 20px;
    }

    .graphContainer{
        margin-left: -29px;
    }
} */