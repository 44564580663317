.editCheckpointsContainer {
    overflow-x: hidden;
    display: flex;
    height: 6vw;
    width: 18vw;
    overflow-y: auto;
    font-family: var(--font1);
    margin-top: 2vw;
    overflow-y: hidden;
    border: solid red 1px;
}

.inputCheck {
    position: relative;
    bottom: 0.5vw;
    text-align: center;
    color: var(--blue);
    width: 15vw;
    height: 2vw;
    margin: auto;
    font-size: var(--h4);
    font-family: var(--font1);
    border: none;
    border-bottom: 0.15vw solid #5e5e5e;
}

.checkList {
    display: flex;
    justify-content: space-between;
    padding: 1%;
    padding-left: 2%;
    /* border: solid rgb(30, 176, 8) 1px; */
    align-items: center;

}

input[type="text"]::placeholder {
    /* Firefox, Chrome, Opera */
    text-align: center;
}


.checkbox {
    position: relative;
    left: -0.5vw;
    width: 1.5vw;
    margin-right: 5%;
}

.checkName {
    position: relative;
    left: 3.5vw;
    display: flex;
    width: 8vw;
    /* justify-content: center; */
    /* border: solid red 1px;*/
    font-size: var(--h4);

}
.behavior{
//    border: solid red 1px;

   font-size: var(--h4); 
}
.checkAndDeleteButton{
    display:flex;
    justify-content: center;
    position: relative;
    left:55%;
    // border:solid red 1px; 
    width: 30%;
}

.deleteButton {
    position: relative;
    padding: 1%;
    left: -0.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1vw;
    cursor: pointer;
    width: 5vw;
    height: 1vw;
    background-color: var(--white);
    border: solid var(--red) 0.15vw;
    color: var(--red)
}

.deleteButtonDisabled {
    position: relative;
    padding: 1%;
    left: -0.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1vw;
    cursor: pointer;
    width: 5.5vw;
    height: 1.5vw;
    background-color: var(--white);

}

// .addButton {
//     width: 40%;
//     height: 25%;
//     margin: auto;
//     border-radius: 2vw;
//     background-color: var(--white);
//     border: solid 0.12vw var(--cyan);
//     color: var(--cyan);
//     cursor: pointer;
//     font-size: var(--h4);
// }
.addButton:disabled {
    cursor: not-allowed;
   // pointer-events: auto;
  }
  
.updateButton {
    width: 30%;
    height: 50%;
    margin: 8% auto;
    border-radius: 2vw;
    background-color: var(--white);
    border: solid 0.12vw var(--cyan);
    color: var(--cyan);
    cursor: pointer;
    font-size: var(--h4);
}

.cancelButton {
    width: 30%;
    height: 50%;
    margin: 8% auto;
    border-radius: 2vw;
    background-color: var(--white);
    border: solid 0.12vw var(--red);
    color: var(--red);
    cursor: pointer;
    font-size: var(--h4);
}
.select{
    width: 9.5vw;
    height: 1.5vw;
    align-items: center;
    font-size: var(--h5);
    border-radius: 2vw;
    text-align: center;
    margin: auto;
    color: var(--blue);

}
.update_cancel_container {
    /* border:solid 1px red; */
    display: flex;
    height: 3vw;
    width: 20vw;
    margin: auto;
    justify-content: space-between;
    align-items: center;
}

.formCustomCheckpoints {
    display: flex;
    flex-direction: column;
    /* border:solid 1px rgb(72, 255, 0); */
    margin-left: 1%;
    /* justify-content: center; */
    /* align-items: center; */
}

.dropdown {
    font-family: var(--font1);
    padding: .2vw;
    width: 7.5vw;
    font-size: var(--h4);
    border-radius: 2vw;
    text-align: center;
    margin: auto;
}
.dropdown2 {
    font-family: var(--font1);
    width: 7.5vw;
    height: 2vw;
    align-items: center;
    font-size: var(--h5);
    border-radius: 2vw;
    text-align: center;
    margin: auto;
}
