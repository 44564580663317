.container {
   // border: solid red 1px;
   width: 35vw;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   position: relative;
   top: 15px;
   //border: solid red 1px;
   margin-bottom: 0.5%;
   //  border: solid red 1px;
}

.datePickerContainer {
   display: flex;
   // flex-direction: column;
   justify-content: center;
   align-items: center;
   // border: solid red 1px;
}

.label {
   font-size: 20px;
   color: var(--blue);
   font-family: var(--font1);
   // margin-right: 1rem;


}

.datePicker {
   background-color: var(--white);
   border: solid 1px var(--white);
   height: 30px;
   width: 150px;
   margin-left: 1rem;
   margin-right: 1rem;
   // margin-top: 5px;
   border-radius: 5px;
   font-family: var(--font1);
   color: var(--blue);
   text-align: center;
   font-size: var(--h4);
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.datePicker:focus {
   outline: none;
}