.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.createTitle{
    margin-bottom: 2vw;
    font-size: 1.5vw;
}

.eachSection{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    /* border: solid 2px blue; */
    width: 100%;
}

.eachInput{
    margin-left: 2vw;
    width: 50%;
    height: 1vw;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-radius: 2vw;
    margin-left: auto;
    text-align: start;
}

.eachLabel{
    text-align: start;
    color: #1B1464;
    margin-right: 2vw;
    font-size: 1vw;
}

.createButton{
    border: 0.15vw solid #00ABC8;
    background-color: white;
    border-radius: 2vw;
    color: #00ABC8;
    font-size: 0.8vw;
    font-weight: normal;
    width: 40%;
    cursor: pointer;
    margin-top: 2vw;
    margin-bottom: 1vw;
    margin-left: 30%;
    padding: 1%;
}

.createButton:disabled,
button[disabled]{
border: 0.15vw solid #999999;
background-color: #cccccc;
color: #666666;
cursor:not-allowed;
}

