@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

:root {
  //colors----------------
  --cyan: #00abc8;
  --blue: #1B1464;
  --red: #FF4D4D;
  --green: #32CD32;
  --greenArea: #00f8004d;
  --yellow: #FFD700;
  --transparent: transparent;
  --white: #FFFFFF;
  --black: #000000;
  --grey: #4c4c50;
  --lightgrey: #aaaaaa;
  --disabled: #999999;
  --borderBottom: "solid rgb(170, 170, 170) 1px";
  --orange:#ff6016;
  //fonts----------------
  --font1: "DM Sans", sans-serif;
  --font2: 'Quattrocento Sans', sans-serif;
  //sizes----------------
  --h0: 2.5vw;
  --h1: 2vw;
  --h11: 1.8vw;
  --h2: 1.5vw;

  --h3: 1.3vw;
  --h31: 1.2vw;
  --h4: 1vw;
  --h5: 0.9vw;
  --h6: 0.8;
  --h7: 0.7vw;
  --h8: 0.5vw;
  //weights--------------
  --w1: 100;
  --w2: 200;
  --w3: 300;
  --w4: 400;
}