.AddAlertForm {

    display: flex;
    flex-direction: column;
    height: 15vw;
}

.newAlertinputName {
    width: 80%;
    border: none;
    border-bottom: 0.12vw solid lightgrey;
    height: 2vw;
    font-size: var(--h31);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--cyan);
    padding-bottom: 2%;
    margin: auto;
}

.max_min {
    display: flex;
    margin-top: 2vw;
    justify-content: space-between;
    border: border solid 2px;
}

.spanAndTemp {
    display: flex;
    align-items: center;
    justify-content: center;

}

.spanTemp {
    border: border solid 1px;

}

.addAlertButton {
    border: 0.15vw solid var(--cyan);
    background-color: white;
    border-radius: 2vw;
    color: var(--cyan);
    font-size: var(--h5);
   // font-weight: normal;
    width: 35%;
    height: 2vw;
    cursor: pointer;
    padding: 0.3%;
    margin: auto;
    margin-top: 3vw;

}