.myOperatorsContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;

}

.pagination {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left: auto;
    margin-top: 1vw;
}


.buttonsContainer2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
     /* border:solid red 1px;  */
    /*  padding-right: 5%; */
    width: 100%;
    /*   padding-left: 3%;  */
    position: relative;
    left: -0.5vw;

}

.editOperatorButton {
    border: 0.15vw solid var(--cyan);
    background-color:var(--white);
    color: var(--cyan);
    border-radius: 2vw;
    font-size: var(--h6);
   // font-weight: normal;
    width: 6.5vw;
    height: 1.5vw;
    cursor: pointer;
    margin-right: 12%;
    /*  margin-bottom: 0.4vw; */
    /* padding: 1%; */
}

.deleteOperatorButton {
    border: 0.15vw solid  var(--red);
    background-color: var(--white);
    border-radius: 2vw;
    color:  var(--red);
    font-size: var(--h6);
   // font-weight: normal;
    width: 6.5vw;
    cursor: pointer;
    height: 1.5vw
        /*   margin-top: 1vw; */
        /*  margin-bottom: 0.4vw; */
        /* margin-left: 5vw; */
        /*  padding: 1%; */

}
//quede aca
.cancelButton {
    border: 0.15vw solid  var(--red);
    background-color: var(--white);
    border-radius: 2vw;
    color:  var(--red);
    font-size: var(--h6);
    //font-weight: normal;
    width: 6.5vw;
    cursor: pointer;
    height: 1.5vw
        /*  margin-top: 1vw; */
        /*   margin-bottom: 0.8vw; */
        /*  margin-left: 5vw; */
        /*  padding: 1%; */
}

.saveButton {
    border: 0.15vw solid var(--cyan);
    background-color: var(--white);
    color: var(--cyan);
    border-radius: 2vw;
    font-size: var(--h6);
   // font-weight: normal;
    width: 6.5vw;
    cursor: pointer;
    height: 1.5vw
        /* margin-top: 1vw; */
        /*    margin-bottom: 0.8vw; */
        /*  padding: 1%; */
}

.select {
    font-family:  var(--font1);
    padding: 2%;
    font-size: var(--h4);
    border-radius: 2vw;
}
.inputContainer {
    margin-left: auto;
    display: flex;
    flex-direction: row;
}
.searchQrLabel {
    margin-right: 0.5vw;
    margin-top: 0.3vw;
}
.searchQrInput {
    border-radius: 1.6vw;
    border-color: #D9F1F5;
    padding: 2%;
    font-family:  var(--font1) !important;
    font-size: var(--h4);
    width: 90%;
}

.noData {
    width: 100%;
    color: var(--blue);
    font-size: var(--h2);
    margin-top: 2vw;
    margin-bottom: 2vw;
    text-align: center;
}

.dropdownButton {
    background-color: var(--transparent);
    border: none;
    cursor: pointer;
    color: var(--cyan);
}

.statusContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.show_input {
    color:var(--red);
}

.lista_de_access {
    display: flex;
    /* flex-direction: column; */

}

.not_found_msj {
    display: flex;
    width: 100%;
    color: var(--blue);
    font-size: 2vw;
    font-family:  var(--font1);
    justify-content: center;
    margin-top: 2vw;
}

.access_to_edit {
    display: flex;
    flex-direction: column;

}

.access_container {
    display: flex;
    /* border: solid orange 1px; */
    justify-content: center;
}

.select_container_access_to {
    display: flex;
    justify-content: center;
}

.span_access_to {
    /* color: red; */

    /* border: solid red 1px; */
    text-align: center;
    padding-right: 1vw;
    position: relative;
    left: .5vw
}

.select_access_to {

    border: none;
    width: 1vw;
    cursor: pointer;
    overflow: hidden;
    outline: 0;
    font-size: var(--h2);
    background-color: var(--white);
    color: var(--cyan);
    position: relative;

    left: -0.2vw;
    /* left: -1vw; */
    /* border: solid 1px green */
}

.select2_access_to {
    border: none;
    width: 1vw;
    cursor: pointer;
    overflow: hidden;
    outline: 0;
    font-size: var(--h2);
    background-color: #D9F1F5;
    position: relative;
    left: -0.2vw;
    color: var(--cyan);

}

.option {
    background-color: var(--white);
    border-color: var(--white);
}

.span_checkedTrueByDefault {
    display: flex;
    /* border: solid green 1px; */
    position: relative;
    left: 2.7vw
}

.span_checkedFalseByDefaut {
    display: flex;
    position: relative;
    left: 2.7vw;
    /* border: solid red 1px; */


}


@media (max-width:800px) {
    .editOperatorButton {
        font-size: var(--h2);
        width: 8vw;
        /*   padding: 4%; */

    }

    .deleteOperatorButton {
        font-size: var(--h2);
        width: 8vw;
        /*   padding: 4%; */
        margin-left: 2vw;
    }

    .cancelButton {
        font-size: var(--h2);
        width: 8vw;
        padding: 4%;
        margin-left: 2vw;
    }

    .saveButton {
        font-size: var(--h2);
        width: 8vw;
        padding: 4%;
    }

    .select {
        padding: 4%;
        font-size: 2vw;
    }
}