.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-left: 2%;


}

.title {
    color:var(--blue);
    font-size: var(--h1);
    width: 50%;
    text-align: center;
  //  border: solid red 1px;

}

/* @media (max-width:800px){
    .title{
        font-size: 5vw;
    }
} */