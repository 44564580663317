.container {
    background-image: none;
    background-color: rgba(145, 145, 145, 0.623);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin: auto;
    border: solid rgb(106, 106, 107) 1px;
   // padding: 2%;
}



.cardContainer {
    background-color: var(--white);
   // border: solid rgb(3, 33, 207) 1px;
    width: 350px;
    height: 250px;
    margin: auto;
    border-radius: 10px;
}
.title {
    display: flex;
    justify-content: center;
    align-items: center;
    //border: solid 1px red;
    font-size: 1.7rem;
    font-family: var(--font1);
    color: var(--blue);
    margin-top: 20px;
}
.infoContainer {
    display: flex;
    //flex-direction: column;
    justify-content: center;
    align-items: center;
   // border: solid 1px rgb(0, 189, 0);
    width: 100%;
    height: 85%;
}
.qr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100%;
  //  border: solid red 1px;
}
.qrNumber {
    font-family: var(--font1);
    font-size: 1.5rem;
    //color: var(--blue);
    position: relative;
    //top: -40px;
}
.tempContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
    height: 100%;
    //border: solid rgb(43, 44, 44) 1px;
 
}
.temp{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40%;
   // border: solid red 1px;
    font-size: 3rem;
    font-family: var(--font1);
    color: var(--blue);

}
.battery{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40%;
  //  border: solid red 1px;
    font-size: 1.5rem;
    font-family: var(--font1);
    color: var(--blue);
}