.container {
    display: flex;
    flex-direction: column;
    margin-top: 1vw;
    width: 88vw;
    margin-bottom: 5vw;
    padding: 0px !important;
}

.accordion {
    border: 0.1vw solid var(--cyan);
    border-left: 0.15vw solid var(--cyan);
    border-right: 0.15vw solid var(--cyan);
    

}

.accordionTop {
    border-top: 0.15vw solid var(--cyan);
    border-bottom: 0.1vw solid var(--cyan);
    border-left: 0.15vw solid var(--cyan);
    border-right: 0.15vw solid var(--cyan);
    
    
}

.accordionBottom {
    border-top: 0.1vw solid var(--cyan);
    border-bottom: 0.15vw solid var(--cyan);
    border-left: 0.15vw solid var(--cyan);
    border-right: 0.15vw solid var(--cyan);
}

/* GEN DATA ------------------------------------------------------------- */


// .editData {
//     font-size: "1.2vw";
//     margin-left: 15%;
//     /* border:solid 1px red; */
//     width: 25vh;
//     overflow-x: auto;
    
// }

// .editDataTempContainer {
//     display: flex;
//     width: 100%;
//     margin-top: 1.5vw;
//     justify-content: space-between;
// }

// .editDataTempContainerAcc {
//     display: flex;
//     width: 100%;
//     margin-top: 1.8vw;
//     justify-content: space-between;
// }

// .editDataTemp {
//     width: 30%;
// }


// .tempTitle {
//     width: 100%;
//     border-bottom: 0.12vw solid lightgrey;
//     height: 2vw;
//     font-size: 1.2vw;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: var(--cyan);
//     padding-bottom: 2%;
// }
// .tempTitle_input{
//     width: 100%;
//     text-align: center;
//     border:none;
//     height: 2vw;
//     font-size: 1.2vw;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: var(--cyan);
//     padding-bottom: 2%;
//     border-bottom: 0.15vw solid rgb(192, 192, 192);
// }


// .dataTempContainer {
//     width: 100%;
//     display: flex;
// }

// input[type=number]::-webkit-inner-spin-button,
// input[type=number]::-webkit-outer-spin-button {

//     opacity: 1;

// }

// .eachTempDataContainer {
//     display: flex;
//     width: 50%;
//     padding: 3%;
// }

// .eachAccDataContainer {
//     display: flex;
//     width: 100%;
//     justify-content: center;
//     padding: 6%;
//     align-items: center;
//     margin-top: 0.2vw;
// }

.cancelButton {
    border: 0.15vw solid var(--red);
    background-color: var(--white);
    color: var(--red);
    border-radius: 2vw;
    font-size: var(--h4);
    font-weight: normal;
    width: 10%;
    cursor: pointer;
    padding: 0.3%;
    margin-top: 0.2vw;
    margin-left: 1%;
}

// .eachDataTempTitle {
//     font-size: 1.2vw;
//     margin-right: 15%;
//     color: var(--blue);
// }

// .dataNumber {
//     font-size: 1.2vw;
//     width: 60%;
//     color: var(--blue);
// }

.editButton {
    border: 0.15vw solid var(--cyan);
    background-color: white;
    border-radius: 2vw;
    color: var(--cyan);
    font-size: var(--h4);
    font-weight: normal;
    width: 10%;
    cursor: pointer;
    padding: 0.3%;
    margin-top: 0.2vw;
}


.barcodesInput {
    margin-left: 2%;
}

.dropdownButton {
    background-color: var(--transparent);
    border: none;
    cursor: pointer;
    color: var(--cyan);

}

// .all_alerts_container {
//     width: 98%;
//     display: flex;
//     flex-direction: row;
//     margin-top: 1vw;
// }

// .temp_alerts_container {
//     width: 100%;
// }

// .acc_alerts_container {
//     width: 40%;
//     margin-left: 8%;
// }

// .type_alert_title {
//     width: 100%;
//     padding: 1%;
//     text-align: center;
//     height: 2vw;
//     font-size: 1.2vw;
//     color: var(--cyan);
//     border-bottom: 1px solid lightgrey;
// }

// .type_alert_titleAcc {
//     width: 100%;
//     padding: 1%;
//     margin-top: 0.7vw;
//     text-align: center;
//     height: 2vw;
//     font-size: 1.2vw;
//     color: var(--cyan);
//     border-bottom: 1px solid lightgrey;
// }

/* COMPANIES-------------------------------------------------------------------------- */
.barcodes_list {
    display: flex;
    flex-direction: column;
    height: 10vw;
    width: 10vw;
    overflow-y: auto;
    margin: auto;
   

}

.barcodes_see {
    /* border: solid red 1px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.barcodes_dropdowList {
    display: flex;
    flex-direction: column;

}

.input_location {

    text-align: center;
    border: none;
    border-bottom: 0.15vw solid rgb(192, 192, 192);
    font-family: var(--font1);
    color: var(--blue);
    width: 25vh;

}


.input_contact_info {
    text-align: center;
    border: none;
    border-bottom: 0.15vw solid rgb(192, 192, 192);
    font-family: var(--font1);
    color: var(--blue);
    width: 25vh;

}




/* BRANCHES ---------------------------------------------------------------------------------- */

.editDataBranches {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.eachEditBranchSection {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
}


.addBranchMainTitle {
    text-align: center;
}


.addBranchContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 80%;
}

.branchesButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/* USERS ------------------------------------------------------------------- */


.createUserButton {
    border: 0.15vw solid var(--cyan);
    background-color: var(--white);
    border-radius: 2vw;
    color: var(--cyan);
    font-size: var(--h4);
    font-weight: normal;
    width: 12%;
    cursor: pointer;
    /*  padding: 0.3%; */
    margin-top: 1vw;
    margin-bottom: 1vw;
}

.eachBarcode {
    margin-right: auto;
}

.createShipperButton {
    border: 0.15vw solid var(--cyan);
    background-color: var(--white);
    color: var(--cyan);
    border-radius: 2vw;
    font-size: var(--h4);
    font-weight: normal;
    width: 12%;
    cursor: pointer;
    /*  padding: 0.3%; */
    margin-top: 1vw;
    margin-bottom: 1vw;

}

@media (max-width:800px) {
    .type_alert_title {
        font-size: 2vw;
    }

    .type_alert_titleAcc {
        font-size: 2vw;
        width: 130%;
        margin-left: -3vw;
    }

    .eachDataTempTitle {
        font-size: 1.8vw;
    }

    .dataNumber {
        font-size: 1.8vw;
    }

   
    .alert_title_input{
        font-size: 2vw;
        height: 2vw;
        color: red;
        border: none;
        width: 5px;
    }
    .cancelButton {
        font-size: 2.5vw;
        width: 15%;
        padding: 1.8%;
    }

    .editButton {
        font-size: 2vw;
        width: 15%;
        /*  padding: 1.8%; */
    }

    .createUserButton {
        font-size: 1.2vw;
        width: 16%;
        /*   padding: 1%; */
        margin-top: 2vw;
    }

    .createShipperButton {
        font-size: 1.2vw;
        width: 18%;
        /*  padding: 1%; */
        margin-top: 2vw;
    }
}