/* .btnLocation {
    display: flex;
    z-index: 999;
    width: 13%;
    height: 5%;
    position: relative;

    align-items: center;
    justify-content: center;
    font-size: 1.5vw;
    color: #fafafa;
    background-color: #00ABC8;
    border-radius: 0.5vw;
    padding: 0.5vw;
    cursor: pointer;
} */

.button {
    z-index: 999;
    border: none;
    position: relative;
    background-color: #00ABC8;
    border-radius: 4em;
    font-size: 16px;
    color: white;
    padding: 0.8em 1.8em;
    cursor: pointer;
    user-select: none;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    top: 5%;
    left: 5%;
    /* Safari */
}

.button:hover {
    transition-duration: 0.1s;
    background-color: #006ec8;
}

.button:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 4em;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 10px 40px white;
}

.button:active:after {
    box-shadow: 0 0 0 0 white;
    position: absolute;
    border-radius: 4em;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
}

.button:active {
    top: 4%;
}