.loading {
    padding: 3%;
}
.buttonsContainer{
   
    display:flex;
    justify-content: space-between;
    margin-top: 3vw;
}
.assignUserInput {
    margin: auto;
    width: 60%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-radius: 2vw;
    padding: 1.5%;
    font-size: 1vw;
    font-family: 'Quattrocento Sans', sans-serif;
}
.confirmAssignButton {
    border: 2px solid #00ABC8;
    background-color: white;
    border-radius: 2vw;
    color: #00ABC8;
    font-size: 0.8vw;
    font-weight: normal;
    width: 20%;
    cursor: pointer;
    margin-top: 0.4vw;
    margin-bottom: 0.6vw;
    padding: 1.5%;
   
}
.cancelButton {
    border: 0.15vw solid #D60707;
    background-color: white;
    color: #D60707;
    border-radius: 2vw;
    font-size: 0.8vw;
    font-weight: normal;
    width: 20%;
    cursor: pointer;
    margin-top: 0.4vw;
    margin-bottom: 0.6vw;
    padding: 1.5%;
   

    /* padding: 1.5%; */
}