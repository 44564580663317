.graphContainer {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    //border: 0.15vw solid #00abc8;
    border-radius: 1vw;
    width: 40%;
    background-color: var(--white);
    margin: 1%;
    padding-top: 20px;
}

.container {
    width: 100%;
    height: 100%;
    //border:solid rgb(19, 192, 13) 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title {
    //border:solid red 1px;
    width: 95%;
    font-size: var(--h2);
    color: var(--blue);
    font-family: var(--font1);
    margin-left: 30px;
}

.textCentered {
    font-size: 2.8vw;
    color: var(--blue);
}

.textCenteredMin {
    font-size: 2.6vw;
    color: var(--blue);
}

.chartAndTitleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    //  flex-direction: column;
    width: 100%;
    margin: 1%;
    margin-top: 0.5vw;

}

.chartContainer {
    // border: solid red 1px;
    display: flex;

    justify-content: center;
    align-items: center;
    width: 45%;

    position: relative;
    left: 10%;
    // top:10px;
    padding: 2%;
    margin-right: 2%;
}

.centered {
    position: absolute;
    top: 50%; //50
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-family: var(--font1);
    //border: solid red 1px;
}

.cardsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //border: solid rgb(45, 76, 161) 1px;
    width: 46%;
    // height: 60%;
    position: relative;
    //   top:10px;
    margin-left: 10%;
    // border: solid red 1px;
}

.circle_green {
    font-family: var(--font1);
    background: var(--green);
    padding: 10%;
    border-radius: 50%;
    display: block;
    width: 0.8vw;
    height: 0.8vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);

    font-size: var(--h2);
    margin: 2%
}

.circle_red {
    font-family: var(--font1);
    background: var(--red);
    padding: 10%;
    border-radius: 50%;
    display: block;
    width: 0.8vw;
    height: 0.8vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);

    font-size: var(--h2);
    margin: 2%
}

.circle_yellow {
    font-family: var(--font1);
    background: var(--yellow);
    padding: 10%;
    border-radius: 50%;
    display: block;
    width: 0.8vw;
    height: 0.8vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);

    font-size: var(--h2);
    margin: 2%
}

.loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75%;
    /* Full viewport height */
}

// dashboard 2 semaforo---------------------------------------------------
.cardsContainer2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //border: solid rgb(45, 76, 161) 1px;
    width: 46%;
    // height: 60%;
    position: relative;
    top: -3.5vw;
    margin-left: 10%;
    // border: solid var(--red) 1px;
}

.circleTextContainer {
    display: flex;
    // justify-content: center;
    align-items: center;
    width: 100%;
    // margin: 1%;
    //border: solid var(--red) 1px;
}

.circle_green2 {
    background: var(--green);
    padding: 10%;
    border-radius: 50%;
    display: block;
    width: 0.8vw;
    height: 0.8vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);

    font-size: var(--h3);
    margin: 3%
}

.circle_red2 {
    background: var(--red);
    padding: 10%;
    border-radius: 50%;
    display: block;
    width: 0.8vw;
    height: 0.8vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);

    font-size: var(--h3);
    margin: 3%
}

.circle_yellow2 {
    background: var(--yellow);
    padding: 10%;
    border-radius: 50%;
    display: block;
    width: 0.8vw;
    height: 0.8vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);

    font-size: var(--h3);
    margin: 3%
}

.loading2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: relative;
    top: -15%;
    // border: solid var(--blue) 1px;
    width: 100%;

}

.labelSuccess {
    color: var(--green);
    font-size: var(--h4);
    font-family: var(--font1);
    margin: 1%;
}

.labelUncert {
    color: var(--yellow);
    font-size: var(--h4);
    font-family: var(--font1);
    margin: 1%;
}

.labelFailed {
    color: var(--red);
    font-size: var(--h4);
    font-family: var(--font1);
    margin: 1%;
}

@media (max-width: 800px) {
    .graphContainer {
        width: 50vw;
        height: 50vw;
        padding-bottom: 2vw;
        margin: 2%;
    }

    .title {
        font-size: 5vw;
        margin-top: 5vw;
        margin-bottom: 2vw;
    }

    .textCentered {
        font-size: 10vw;
        margin-bottom: 30vw;
    }

    .textCenteredMin {
        font-size: 9.8vw;
        margin-top: 2vw;
    }

    .totalInfoContainer {
        height: 38vw;
    }
}