.container {
    width: 100%;
    padding: 0.5%;
    display: flex;
    flex-direction: column;
/* border: solid rgb(237, 103, 0) 1px; */
align-items: center;
}
.switchContainer{
    /* border: solid var(--red) 1px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.titleCalibrations {
    color: var(--blue);
    font-size: var(--h1);
    /* border: solid rgb(37, 182, 21) 1px; */
    width: 50%;
    text-align: center;
   
   
}

.formContainer {
 
    /* border: solid black 1px; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* flex-direction: row; 
    border: solid var(--red) 1px;
    justify-content: right; */
    /* padding-left: 8%; */
}

.filter {
  /* border: solid black 1px; */
    display: flex;
    display: flex;
    justify-content: center;
    
}
.statusTitle{
    font-family: var(--font1);
    color: var(--blue);
}
.select_from_to {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 0.15vw solid var(--cyan);
    border-radius: 2vw;
    height: 1.4vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
    width: 25%;
}
.select_qr {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 0.15vw solid var(--cyan);
    border-radius: 2vw;
    height: 1.4vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
    width: 25%;
}


 input[ id='from_to']::before {
    color: var(--blue);
   
    content: attr(placeholder);
    margin-left: 2%;
    margin-top: 16%;
    width: 100%;
    padding: 3%;
    
    font-size: var(--h4);
   
    display: inline-block;

} 


/* 
input[ type='date']:focus::before {
    content: "" !important;
    display: none;
    position: relative;
    width: 100%;

} */

.origen_destino {
    /* border: solid var(--red) 1px; */
    width: 15%;
    display: flex;
    flex-direction: column;
}

.destinatario_remitente {
    /* border: solid var(--red) 1px; */
    width: 15%;
    display: flex;
    flex-direction: column;
    /* position: relative;
    left: 20% */
}

.desde_hasta {
    /* border: solid var(--red) 1px; */
    width: 10%;
    display: flex;
    flex-direction: column;
}

.estado_codBarras_qr_id {
    /* border: solid var(--red) 1px; */
    width: 90%;
    display: flex;
    /* align-items: center; */

}





.select_qr::placeholder {
    font-weight: normal;
    color: var(--blue);
    margin-left: 80%;
}



.pagination {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 1vw;
    margin-bottom: 1vw;
    height: 2vw;
}

.button {
    background:var(--transparent);
    border: none;
    cursor: pointer;
    z-index: 100;
}

.filterTitle {

    margin: auto;
    font-size: var(--h4);


}

.title {
    color: var(--blue);
    font-size: var(--h0);
    margin-left: 46%;
}

.option {
    border-radius: 2vw;
}

.searchButton {
    background-color: var(--blue);
    border: 0.15vw solid var(--blue);
    color: var(--white);
    height: 2vw;
    border-radius: 2vw;
    width: 10%;
    font-size: var(--h3);
    font-family: var(--font1);
    cursor: pointer;
    margin-top: 0.5vw;
}
.checkbox {
    /* border: solid var(--red) 1px;  */
    display: flex;
    height: 3vw;
}
.searchButtonContainer {
    width: 100%;
    display: flex;
    margin-top: 1vw;
    justify-content: center;
    /* border:solid var(--red) 1px; */
    align-items: center;
   
}

.message1 {
    margin-top: 4vw;
    width: 98%;
    text-align: center;
    font-size: var(--h1);
    color: var(--blue);
    padding: 1%;
}

.message2 {
    margin-left: 42%;
    margin-top: 3vw;
    font-size: var(--h1);
    color: var(--blue);
    padding: 1%;
}

.loading {
    /* border: solid var(--red) 1px; */
   display: flex;
   justify-content: center;
    margin-top: 5vw;
}

/* .selectCodeBar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
} */


.commentsButton {
    color: var(--blue);
    background-color: transparent;
    border: none;
    font-size: var(--h4);
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
}




/* 
.searchQrInput {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 0.15vw solid var(--cyan);
    border-radius: 2vw;
    height: 2vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
} */

.checkboxContainer {
    display: flex;
    flex-direction: row;
    /* border: solid var(--red) 1px; */
    width: 3.5vw;
    justify-content: space-between;
}

.ClearButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    position: relative;
    top: 5%;
}

.clearButton {

    position: relative;
    background-color: var(--white);
    border: solid 0.2vw var(--var(--red));
    border-radius: 4em;
    /* font-size: 16px; */
    color: var(--var(--red));
    font-size: var(--h4);

    font-family: var(--font1);

    cursor: pointer;
    cursor: pointer;

    height: 2vw;
    width: 7.5vw;
    align-items: center;
    justify-content: center;
    /* Safari */
}

.tableContainer {
    /* border: solid var(--red) 1px; */
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 2vw;

}

.headerContainer {
    width: 97%;
    display: flex;
    position: relative;
    left: 1.5%;
    justify-content: space-between;
    border-bottom: solid var(--lightgrey) 1px;
    /* border: solid var(--red) 1px;  */

}
.headerContainerInTransit{
    width: 97%;
    display: flex;
    position: relative;
    left: 0.5%;
    justify-content: space-between;
    border-bottom: solid var(--lightgrey) 1px;
    /* border: solid var(--red) 1px;  */

}

.header_item {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: solid black 1px; */
    color: var(--cyan);
    font-size: var(--h31);
   
    height: 2vw;

}

.check_data_container {
    /* border: solid rgb(37, 77, 143) 1px; */
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    
}

.checkbox {
    /* border: solid var(--red) 1px;  */
    display: flex;
    height: 3vw;
}

.dataContainer {

    width: 100%;
    position: relative;
    /* left:3%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.data {
    display: flex;
    justify-content: center;
   
    align-items: center;
    color: var(--blue);
    font-size: var(--h4);
    /* border: solid var(--red) 1px; */

}

.downloadContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--blue);
    
    font-size: var(--h4);
    /* border: solid rgb(0, 81, 255) 1px; */
}

.rombo {
    width: 1.5vw;

    /* position: relative;
    left: 0.5vw */
}

.error_text {
    display: flex;
    color: var(--red);
    justify-content: center;
    align-items: center;
    /* margin-top: 2%; */
    height: 2vw;

}