.graphContainer {
    width: 98%;
    height: 32vw;
}

.textContainer {
    /* border: solid red 1px; */
    display: flex;
    flex-direction: column;
    width: 4vw;
    height: 3.5vw;
    position: relative;
    bottom: 80%;
    left: 80%;
}

.text {

    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1vw;
}

.textLineBlue {
    width: 80%;
    height: 1%;
    border: solid mediumaquamarine 1px;
    background-color: mediumaquamarine;
}

.textLineYellow {
    width: 80%;
    height: 1%;
    border: solid orange 1px;
    background-color: orange;
}

.textLineCyan {
    width: 80%;
    height: 1%;
    border: solid steelblue 1px;
    background-color: steelblue;
}

.noData_msj {
    margin-top: 2vw;
    margin-left: 20%;
    font-size: 2vw;
    color: #1B1464;
}
.loading{
    margin-top: 20vw;
    margin-left: 47%;
}