$border-color: #00abc8;
$font-size: 0.8vw;
$border-radius: 1vw;
$padding: 1%;
$height: 0.8vw;

.my_button {
    border: 0.15vw solid #00abc8;
    background-color: white;
    border-radius: 2vw;
    color: #00abc8;
    font-size: 0.8vw;
    font-weight: normal;
    width: 7vw;
    cursor: pointer;
    padding: 1.5%;
    margin: 2%;
}
//branchesFilter
.branches_filter_button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.15vw solid $border-color;
    background-color: white;
    border-radius: $border-radius;
    color: $border-color;
    font-size: $font-size;
    cursor: pointer;
    padding: $padding;
    height: $height;
}

.branches_filter_button_active {
    @extend .branches_filter_button;
    background-color: $border-color;
    color: white;
}
//user Filter