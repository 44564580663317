.loginContainer {
    align-items: center;
    justify-content: center;
   
}
.container {
    display: flex;
    justify-content: center;
    margin-top: 8vw;
    //color: #00abc8;
    width: 100%;
   // height: 100%;
    //border: solid red 1px;
}

.inputContainer {
    width: 40%;
    height: 13vw;
    display: flex;
    flex-direction: column;
    // margin-top: 3vw;
    //border: solid red 1px;
}

.eye {
    position: relative;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    bottom: 45px;
    color: rgb(132, 125, 125);
    // border: solid red 1px;
}
.forgotPass {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    text-decoration: none;
    color: rgb(132, 125, 125);
    font-family: var(--font1);
}

.forgotPass:hover {
    color: var(--blue);
}

.loginButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    margin-left: auto;
    margin-top: 1vw;
    background-color: var(--cyan);
    border: 0.15vw solid var(--cyan);
    color: var(--white);
    height: 2vw;
    border-radius: 0.5vw;
    font-size: var(--h3);
    font-family: var(--font1);
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo {
    width: 25%;
    // height: 4.3vw;
    margin-top: 5vw;
    margin-left: 3%;
}

.errorMessage {
    color: #ff001f;
    font-size: 1.1vw;
}

.modal_title {
    display: flex;
    color: #00abc8;
    margin: auto;
    font-size: 1.3vw;
    justify-content: center;
    padding: 1.5vw;
}

@media (max-width: 800px) {
    .container {
        margin-top: 50vw;
    }

    .loginButton {
        width: 30%;
        height: 6vw;
        padding: 4%;
        font-size: 2.5vw;
    }
}
