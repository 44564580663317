.nav {
    display: flex;
   // justify-content: flex-end;
    width: 98%;
    //margin-left: 2%;
   padding-left: 1%;
    margin-bottom: 0.5vw;
    margin-top: 1vw;
  // border: solid red 1px;
   align-items: center;
}



.username {
    display: flex;
    align-items: center !important;

    color: var(--blue);
    margin-top: 0.8vw;
    font-size: 1.3vw;
    font-family: var(--font1);
    position: relative;
    top:-5px;
    cursor: pointer;
   // border: solid rgb(66, 199, 22) 1px;
}

.notification {
    margin-right: 1vw;
    background: transparent;
    border: none;
    cursor: pointer;
    margin-top: 0.2vw;
}

.dropdownButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    
    cursor: pointer;
    //border: solid red 1px;
}

.logo {
    max-width: 28vw;
    max-height: 2.5vw;
    object-fit: cover;
    display: inline-flex;
    margin-right: auto;
    position: relative;
    top: 0.5vw;
}


.notiAlert {
    background: #FF001F;
    position: absolute;
    padding: 0.2%;
    border-radius: 50%;
    font-size: 11px;
    padding-left: 0.3%;
    padding-right: 0.3%;
    color: white;
    margin-left: -0.7vw;
}

.userData {
    display: flex;
    align-items: center ;

}
.avatar_nameContainer{
   // border: solid red 1px;
    display: flex;
    align-items: center;
    position: relative;
    left: -5px;
}
.notiDate {
    font-size: 0.9vw;
    color: black;
}

.flagIcon {
    max-width: 2.2vw;
    max-height: 1.2vw;
    cursor: pointer;
}

.flagIcon2 {
    max-width: 2.2vw;
    max-height: 1.2vw;
    cursor: pointer;
}

.languageButton {
    background-color: #F0F5F8;
    border: 2px solid #F0F5F8;
    /* margin-top: 12px; */
    margin-top: 0.5vw;
    cursor: pointer;
}

.languageDropdown {
    /* margin-bottom: 10px; */
    margin-bottom: 0.5vw;
}

.eachNoti {
    white-space: normal;
}

.notificationIcon {
    width: 1.5vw;
    height: 1.5vw;
}

@media (max-width:800px) {
    .flagIcon {
        max-width: 4vw;
        max-height: 4vw;
    }

    .flagIcon2 {
        max-width: 4vw;
        max-height: 4vw;
    }

    .notificationIcon {
        width: 3vw;
        height: 3vw;
    }

    .username {
        font-size: 2.5vw;
        margin-top: 1vw;
    }
}



@media (max-width: 280px) {
    .logo {
        display: none;
    }
}