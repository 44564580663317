.container{
    /* border: solid blue 1px; */
    display: flex;
    justify-content: space-between;
    height: 5vw;
}
.oldCommentsContainer{
    /* border: solid rgb(0, 255, 55) 1px; */
    height: 5vw;
    overflow: auto;
    width: 45%;
}

.eachComment{
    display: flex;
    flex-direction: row;
    font-size: var(--h4);
    justify-content: space-between;
    font-family: var(--font1);
    /* border: 0.15vw solid var(--blue); */
    background-color: #00aac82f;
    color: var(--blue);
    /* border-radius: 2vw; */
    padding: 0.5%;
    margin-bottom: 0.5%;
    height: 3vw;
    overflow: auto;
}
.text{
    /* border: solid red 1px; */
    width: 80%;
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 5px;

}
.noText{
    
    position: relative;
    top: 2vw;
}
.date{
    /* border: solid green 1px; */
    font-size: var(--h7);
    width: 15%;
}