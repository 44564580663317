
.check {
    width: 2vw;
    height: 1vw;
    border: solid var(--var(--red)) 1px;
}
.loading{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 45%;
 
}
.filterContainer{
    /* border: solid var(--red) 1px; */
    display: flex;
    justify-content: center;
    align-items: center;

}
.filterFrom_to{
margin: 1vw;
font-family: var(--font1);
font-size: var(--h4);
border: 0.15vw solid var(--cyan);
border-radius: 2vw;
height: 1.4vw;
color: var(--blue);
padding: 0.15vw;
width: 8vw;
}
.dataToShow{
    color: var(--blue);
    font-size: var(--h4);
    display: flex;
    align-items: center;
    justify-content: center;
}
.noAlerts{
 display: flex;
 justify-content: center;
 align-items: center;
  font-family: var(--font1);
  font-size: var(--h1);
  color: var(--blue);
  position: relative;
  top: 35%;
 
}
.sendButton{
    background-color: var(--blue);
    border: 0.15vw solid var(--blue);
    color: var(--white);
    height: 1.9vw;
    border-radius: 10px;
    width: 7vw;
    font-size: var(--h4);
    font-family: var(--font1);
    cursor: pointer;
    margin: 1vw;

}
.unsolvedButton{
    background-color: var(--white);
    border: 0.15vw solid var(--red);
    color: var(--red);
    height: 1.9vw;
    border-radius: 10px;
    width: 7vw;
    font-size: var(--h4);
    font-family: var(--font1);
    cursor: pointer;
    margin: 1vw;
}
.solvedButton{
    background-color: var(--white);
    border: 0.15vw solid var(--cyan);
    color: var(--cyan);
    height: 1.9vw;
    border-radius: 10px;
    width: 7vw;
    font-size: var(--h4);
    font-family: var(--font1);
    cursor: pointer;
    margin: 1vw;
}

.datePicker{
    border: solid black 1px;
    border-radius: 10px;
    width: 6vw;
    height: 1.5vw;
    text-align: center;
    font-size: var(--h5);
    color: var(--blue);
    z-index: 1000;
    position: relative;
    font-family: var(--font1);
}
input[id=alertDatePicker]::before {
    color: var(--blue);
    content: attr(placeholder);
    margin-left: 2%;
    margin-top: 1.2vw;
    width: 100%;
    padding: 3%;
    font-size: var(--h4);
    display: inline-block;

}

input[ type='date']:focus::before {
    content: "" !important;
    display: none;
    position: relative;

}
.filterLabel{
    font-family: var(--font1);
    font-size: var(--h4);
    color: var(--blue);
    margin: 0.5vw;
}
