.container {
    display:flex;
    flex-direction: column ;
     /* border:solid red 1px;  */
    height: 70%;
    overflow-y: auto;
    padding:1vw;
    
}
.user_and_mail_form {
    display:flex;
    flex-direction: column;
    height: 10vw;
    padding:1vw;
    margin:1vw
}

.message {
    display:flex;
    color:#00abc8;
    font-size: 1.2vw;
    padding:1vw auto;
  /*   border:solid 1px red; */
    margin-top: 2%
    
}
.success_message{
    font-size: 1.2vw;
    color:#00abc8;
    padding-left: 1vw;
}
.error_message {
    font-size: 1vw;
    color:red;
   
}

.new_data_container {
    display:flex;
    flex-direction: column;
  /*    border:solid rgb(19, 0, 128) 1px; */ 
    height: 15vw;
    padding:1vw;
    margin:1vw
    
}

.loginButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7vw;
    height: 2vw;
    margin-left: auto;  
    font-family: 'Quattrocento Sans', sans-serif;
    font-size: 1.1vw;
    
    background-color: white;
    color: #00abc8;
    border: 0.15vw solid #00abc8;
    border-radius: 2vw;
    margin-top: 1vw;
    cursor:pointer;
}
.recoveryCode_Button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9vw;
    height: 2vw;
    margin-left: auto;  
    font-family: 'Quattrocento Sans', sans-serif;
    font-size: 1.2vw;
    
    background-color: white;
    color: #00abc8;
    border: 0.15vw solid #00abc8;
    border-radius: 2vw;
    margin-top: 1vw;
    cursor:pointer;
}

@media (width<= 970px){
    .message {
        margin-top: 3vw;   
    }
   
}
@media (width <= 800px){
    
    .user_and_mail_form {
       
        padding:0.5vw;
        margin:0.5vw
    }
    .new_data_container {
        display:flex;
        flex-direction: column;    
        height: 10vw;
        padding:0.5vw;
        margin-top:5vw;
  
    }
   
    .message {
        margin-top: 5vw;
       
        
    }
   
}