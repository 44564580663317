.select{
    font-family: 'Quattrocento Sans', sans-serif;
    padding: 5px;
    font-size: 16px;
}

.distributorsContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-top: 0.3vw;
}

.editDistributorsButton{
    border: 0.15vw solid #00ABC8;
    background-color: white;
    border-radius: 1vw;
    color: #00ABC8;
    font-size: 1vw;
    font-weight: normal;
    width: 6vw;
    cursor: pointer;
    margin-top: 2vw;
    margin-bottom: 0.8vw;
    padding: 2%;
}

.deleteDistributorButton{
    border: 0.15vw solid #D60707;
    background-color: white;
    border-radius: 1vw;
    color: #D60707;
    font-size: 1vw;
    font-weight: normal;
    width: 6vw;
    cursor: pointer;
    margin-top: 2vw;
    margin-bottom: 0.8vw;
    padding: 2%;
}

.companiesContainer{
    height: 10vw;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    align-items: center;
    justify-content: center;
}

.pagination{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left: auto;
    margin-top: 1vw;
}

.searchQrLabel{
    margin-right: 0.5vw;
}

.searchQrInput{
    border-radius: 2vw;
    border-color: #D9F1F5;
    padding: 2%;
    font-family: 'Quattrocento Sans', sans-serif !important;
    font-size: 1vw;
    width: 90%;
}

.inputContainer {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1vw;
}

@media (max-width:800px){
    .searchQrInput{
        padding: 3%;
        font-size: 2vw;
        width: 25vw;
    }

    .editDistributorsButton{
        font-size: 2vw;
        width: 12vw;
        padding: 3%;
    }
    
    .deleteDistributorButton{
        font-size: 2vw;
        width: 12vw;
        padding: 3%;
    }
    
    .cancelButton{
        font-size: 2vw;
        width: 12vw;
        padding: 3%;
    }
  }