.temp_alerts_container {
    width: 100%;
    /* border: solid red 1px; */
    display: flex;
    flex-direction: column;

}

.type_alert_title {
    /* width: 100%; */
    padding: 1%;
    text-align: center;
    height: 2vw;
    font-size: 1.2vw;
    color: var(--cyan);
    border-bottom: 1px solid lightgrey;

}

.editDataTempContainer {
    display: flex;
    width: 100%;
    margin-top: 1.5vw;
    justify-content: space-between;
    /* border: solid rgb(166, 255, 0) 1px; */
    flex-wrap: wrap;

}

.editDataTemp {
    width: 20%;
    margin: 1%
        /* border: solid rgb(255, 149, 0) 1px; */
}

.tempTitle {
    width: 100%;
    border-bottom: 0.12vw solid lightgrey;
    height: 2vw;
    font-size: 1.2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--cyan);
    padding-bottom: 2%;
}

.tempTitleInput {
    width: 100%;
    border: none;
    border-bottom: 0.12vw solid lightgrey;
    height: 2vw;
    font-size: 1.2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--cyan);
    padding-bottom: 2%;

}

.eachTempDataContainer {
    display: flex;
    width: 50%;
    padding: 3%;

    margin: auto;
}

.eachDataTempTitle {
    font-size: 1.2vw;
    margin-right: 15%;
    color: var(--blue);
}

.dataNumber {
    font-size: 1.2vw;
    width: 60%;
    color: var(--blue);
}

.dataTempContainer {
    width: 100%;
    display: flex;

}

.buttons_container {
    display: flex;
    /* border: solid red 1px; */
    justify-content: center;

}

.editButton {
    border: 0.15vw solid var(--cyan);
    background-color: var(--white);
    border-radius: 2vw;
    color: var(--cyan);
    font-size: 1vw;
//font-weight: normal;
    width: 10%;
    cursor: pointer;
    padding: 0.3%;
    /* margin: auto; */
    margin-top: 2vw;
    margin-right: 0.2vw;
    margin-left: 0.2vw;
}

.saveButton {
    border: 0.15vw solid var(--cyan);
    background-color: var(--white);
    border-radius: 2vw;
    color: var(--cyan);
    font-size: var(--h4);
   // font-weight: normal;
    width: 10%;
    cursor: pointer;
    padding: 0.3%;
    margin-top: 2vw;
    margin-right: 0.2vw;
}

.cancelButton {
    border: 0.15vw solid var(--red);
    background-color: var(--white);
    border-radius: 2vw;
    color: var(--red);
    font-size: 1vw;
   // font-weight: normal;
    width: 10%;
    cursor: pointer;
    padding: 0.3%;
    margin-top: 2vw;
    margin-left: 0.2vw;
}



.remove_alert_button {
    margin-left: 0.5vw;
    margin-top: 1vw;
    width: 5vw;
    height: 1.5vw;
    font-size: var(--h6);
    //font-weight: normal;
    background-color: var(--white);
    border: solid var(--red) 0.15vw;
    color: var(--red);
    cursor: pointer;
    border-radius: 1vw;

}
.toleranceContainer{
    border: solid  lightgrey 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:20px auto ;
  
    width:20%;
    height: 12vw;

}
.toleranceInputsContainer{
    display: flex;
   // flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
    //border: solid green 1px;
    height: 5vw;

}
.inputsAndSpanContainer{
    display: flex;
    flex-direction: column;
    align-items: center;

}

.toleranceTitle{
    font-size: var(--h5);
    color: var(--cyan);
    text-align: center;
   // padding: 1%;
    margin-bottom: 1vw;
}
.toleranceInputs{
    width: 30%;
   height: 2vw;
   font-size: var(--h5);

}
.setTolerance{
    margin-top: 45px;
    width: 45%;
    text-transform: none;
 

}