.dropdownContainer {
    height: 100%;
    width: 100%;
   //border: solid rgb(255, 38, 0) 1px;
    background-color: var(--white);
    display: flex;
}

.buttonCsvDownload {
    position: relative;
    top: -4vw;
    left: 18.7vw;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 100;
    /* color: red; */
}
.buttonDownload {
    border: solid red 1px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 20px;
}

.graphsContainer {
    //height: 96%; //62
    display: flex;
    // justify-content: space-around;
 //border: solid rgb(5, 20, 184) 1px;
    width: 70%;
    padding-top: 0.5%;
}
.checkpointsAndcommentsContainer {
    display: flex;
    flex-direction: column;
    //padding-top: 2%;
    // justify-content: space-between;
    width: 30%;
    // height: 100%;
   // border: solid rgb(5, 44, 184) 1px;
}

// .tempContainer {
//     margin-top: 1vw;
//     width: 80%;
//     height: 100%;
//     // border: solid rgb(5, 44, 184) 1px;
// }

// .accContainer {
//     margin-top: 1vw;
//     width: 40%;
//     height: 100%;
//     /* border: solid rgb(5, 44, 184) 1px; */
// }

.checkpointsContainer {
    height: 50%;
    //border: solid red 1px;
}
.commentsSectionContainer {
    height: 44%;
    margin-top: 6%;
    
   // border:solid rgb(192, 192, 192) 1px;
}
.datePickerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-top: 1vw;
    width: 45%;
    //height: 100%;
    //border: solid rgb(5, 44, 184) 1px;
    position: relative;
    left: 10px;
}
.datePicker {
   // background-color: var(--white);
    border: solid 1px black;
    height: 30px;
    width: 150px;
    margin-left: 1rem;
    margin-right: 1rem;
    // margin-top: 5px;
    border-radius: 10px;
    font-family: var(--font1);
    color: var(--blue);
    text-align: center;
    font-size: var(--h4);
    //box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1); 
 }
.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 100%;
    width: 70%;
    border: solid rgb(5, 44, 184) 1px;
}

// dropdow2-----------------------------------------------------------------------------
.reportsDropdown {
    border: solid red 1px;
    width: 100%;
    height: 100%;
    display: flex;
}
.chartsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 75%;
    border: solid rgb(35, 141, 9) 1px;
}
.charts {
    width: 100%;
    height: 33%;
    border: solid rgb(9, 18, 141) 1px;
}
.sideContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 25%;
    height: 100%;
    border: solid rgb(141, 9, 9) 1px;
}
.routeMap {
    width: 100%;
    height: 66.66%;
    border: solid rgb(88, 9, 141) 1px;
}
.commentsContainer2 {
    width: 100%;
    height: 33.33%;
    border: solid rgb(9, 29, 141) 1px;
}

/* responsive */
/* @media (max-width: 1634px) {
    .buttonCsvDownload {
        left: 18vw;
    }
}
@media (max-width: 1620px) {
    .buttonCsvDownload {
        left: 17vw;
    }
}
@media (max-width: 1634px) {
    .buttonCsvDownload {
        left: 18vw;
    }
} */
/* @media (max-width: 1590px) {
    .buttonCsvDownload {
        left: 17vw;
    }
}
@media (max-width: 1560px) {
    .buttonCsvDownload {
        left: 15.5vw;
    }
}
@media (max-width: 1550px) {
    .buttonCsvDownload {
        left: 15vw;
    }
}
@media (max-width: 1530px) {
    .buttonCsvDownload {
        left: 14.5vw;
    }
}
@media (max-width: 1520px) {
    .buttonCsvDownload {
        left: 14vw;
    }
}
@media (max-width: 1500px) {
    .buttonCsvDownload {
        left: 13.5vw;
    }
}
@media (max-width: 1480px) {
    .buttonCsvDownload {
        left: 13vw;
    }
}
@media (max-width: 1460px) {
    .buttonCsvDownload {
        left: 12.5vw;
    }
}
@media (max-width: 1440px) {
    .buttonCsvDownload {
        left: 12vw;
    }
}
@media (max-width: 1420px) {
    .buttonCsvDownload {
        left: 11.5vw;
    }
}
@media (max-width: 1400px) {
    .buttonCsvDownload {
        left: 11vw;
    }
}
@media (max-width: 1380px) {
    .buttonCsvDownload {
        left: 10.5vw;
    }
}
@media (max-width: 1360px) {
    .buttonCsvDownload {
        left: 10vw;
    }
}
@media (max-width: 1340px) {
    .buttonCsvDownload {
        left: 9.5vw;
    }
}
@media (max-width: 1320px) {
    .buttonCsvDownload {
        left: 9vw;
    }
}
@media (max-width: 1300px) {
    .buttonCsvDownload {
        left: 8.5vw;
    }
}
@media (max-width: 1280px) {
    .buttonCsvDownload {
        left: 8vw;
    }
}
@media (max-width: 1260px) {
    .buttonCsvDownload {
        left: 7.5vw;
    }
}
@media (max-width: 1240px) {
    .buttonCsvDownload {
        left: 7vw;
    }
}
@media (max-width: 1220px) {
    .buttonCsvDownload {
        left: 6.5vw;
    }
}
@media (max-width: 1200px) {
    .buttonCsvDownload {
        left: 6vw;
    }
} */
