.eachSection{
    margin: 2vw;
}

.container{
    overflow-y: auto;
    width: 100%;
}

.infoTitle{
    font-size: 1.2vw;
    margin-right: 1vw;
    margin-bottom: 1vw;
}

.inputs{
    width: 10vw;
    height: 1vw;
}

.branchesTitle{
    font-size: 1.1vw;
    margin-left: 1vw;
    margin-bottom: 1vw;
}

.branchesContainer{
    display: flex;
    width: 100%;
}

.eachBranch {
    border: 0.15vw solid lightgrey;
    display: flex;
    flex-direction: column;
    width: 25%;
    padding: 1.6%;
}

.eachBranchData{
    display: flex;
    margin-top: 0.5vw;
    justify-content: space-between;
    width: 100%;
    font-size: 1.1vw;
}

.addBranchButton{
    border: 0.15vw solid #00ABC8;
    background-color: white;
    border-radius: 2vw;
    color: #00ABC8;
    font-size: 0.9vw;
    font-weight: normal;
    width: 10%;
    cursor: pointer;
    padding: 0.6%;
    margin-top: 1vw;
    margin-left: 1vw;
}

.cancelBranchButton{
    border: 0.15vw solid #D60707;
    background-color: white;
    border-radius: 2vw;
    color: #D60707;
    font-size: 0.9vw;
    font-weight: normal;
    width: 10%;
    cursor: pointer;
    padding: 0.6%;
    margin-top: 1vw;
    margin-left: 1vw;
}

.addBranchContainer {
    margin-left: 2vw;
    margin-top: 2vw;
}

.eachAddBranchData{
    display: flex;
    width: 40%;
    justify-content: space-between;
    margin-top: 1vw;
}
.checkOwned {
    display: flex;
    width: 40%;
    /* justify-content: space-between; */
    margin-top: 1vw; 
}
.operatorsContainer{
    display: flex;
    justify-content: space-between;
    margin-top: 2vw;
    margin-left: 2vw;
    margin-right: 1vw;
    width: 70%;
    border-top: 0.15vw solid lightgrey;
    padding-top: 1%;
}

.addOperatorContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 2vw;
    margin-left: 2vw;
}

.addOperatorButton{
    border: 0.15vw solid #00ABC8;
    background-color: white;
    border-radius: 2vw;
    color: #00ABC8;
    font-size: 1vw;
    font-weight: normal;
    width: 30%;
    cursor: pointer;
    padding: 1%;
    margin-top: 1vw;
    margin-left: 2vw;
}

.addOperatorTitle{
    margin-right: 2vw;
}

.addOperatorInput{
    margin-left: auto;
}

.eachAddOperatorData{
    display: flex;
    width: 40%;
    justify-content: space-between;
    margin-top: 1vw;
}

.addOperatorTypeSelect{
    width: 35%;
    font-family: 'Quattrocento Sans', sans-serif;
    font-size: 1vw;
    /* border: 2px solid black; */
    border-radius: 2vw;
    color: black;
    padding: 1%;
}

.addOperatorButtonsContainer{
    display: flex;
}

/* ALERTAS  */

.all_alerts_container{
    width: 95%;
    display: flex;
    flex-direction: row;
    margin-top: 2vw;
    height: 8vw;
}

.temp_alerts_container{
    width: 55%;
    height: 2vw;
}

.editDataTempContainer{
    display: flex;
    width: 103.5%;
    margin-top: 1vw;
    justify-content: space-between;
}

.editDataTemp{
    width: 30%;
}

.tempTitle{
    width: 100%;
    background-color: #D9F1F5;
    border: 0.1vw solid #BCE2EA;
    height: 2vw;
    font-size: 1.2vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dataTempContainer{
    width: 83.5%;
    width: 100%;
    display: flex;
    
}

input[type=number]::-webkit-inner-spin-button , 
input[type=number]::-webkit-outer-spin-button {  
  opacity: 1;
  
}

.eachTempDataContainer{
    display: flex;
    width: 50%;
    justify-content: center;
    padding-top: 0.5vw;
    border: 0.1vw solid #BCE2EA;
}

.eachAccDataContainer{
    display: flex;
    width: 88.5%;
    justify-content: center;
    padding: 4.9%;
    border: 0.1vw solid #BCE2EA;
}

.eachDataTempTitle{
    font-size: 1vw;
    margin-right: 1vw;
    /* margin-left: 1vw; */
    
}

.dataNumber{
    font-size: 1vw;
   
}

.acc_alerts_container{
    width: 37%;
    /* border: 2px solid blue; */
    height: 1vw;
    margin-left: 5vw;
}

.type_alert_title {
    width: 100%;
    padding-top: 2%;
    margin-right: auto;
    text-align: left;
    padding-left: 2%;
    padding-right: 2%;
    background-color: #BCE2EA;
    height: 1.5vw;
    font-size: 1.1vw;
}

.selects{
    font-family: 'Quattrocento Sans', sans-serif;
    font-size: 1.1vw;
    /* border: 2px solid black; */
    border-radius: 2vw;
    color: black;
    padding: 1%;
    margin-right: 1vw;
    margin: 1vw;
}

.eachOperatorDataContainer{
    display: flex;
    justify-content: space-between;
    margin-right: 1vw;
}

.operatorsDataContainer{
    display: flex;
    flex-direction: column;
    margin-top: 1vw;
    padding-top: 1%;
    border-top: 0.1vw solid lightgrey;
}

.deleteOperatorButton{
    border: 0.15vw solid #D60707;
    background-color: white;
    border-radius: 2vw;
    color: #D60707;
    font-size: 0.9vw;
    font-weight: normal;
    width: 40%;
    cursor: pointer;
    padding: 0.6%;
    margin-top: 1vw;
    margin-left: 1vw;
}

.eachTypeOfOperator{
    width: 25%;
}

.eachBarcode{
    margin-right: 0.8vw;
    font-size: 1vw;
}

.noOperator{
    margin-left: 28%;
    margin-top: 2vw;
}

.createCompanyButton{
    border: 0.15vw solid #00ABC8;
    background-color: white;
    border-radius: 2vw;
    color: #00ABC8;
    font-size: 1.1vw;
    font-weight: normal;
    width: 20%;
    cursor: pointer;
    padding: 1%;
    margin-top: 2vw;
    margin-left: 40%;
}

.cancelButton{
    border: 0.15vw solid #FF001F;
    background-color: white;
    border-radius: 2vw;
    color: #FF001F;
    font-size: 1.1vw;
    font-weight: normal;
    width: 20%;
    cursor: pointer;
    padding: 1%;
    margin-top: 2vw;
    margin-left: 40%;
}

.loading{
    margin-top: 2vw;
}
