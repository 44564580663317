.container{
    // border: solid rgb(163, 16, 16) 1px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    
}
.buttonsContainer{
    
    display: flex;
    justify-content: space-around;
}
.timeInTransit {
    font-size: 1vw;
   padding: 2%;
    color:var(--white);
 //   border: solid rgb(224, 221, 221) 1px;
    text-align: center;
    background-color: darkgrey;
    margin-bottom: 2%;;
    border-radius: 10px;
}
.button {
    background: transparent;
    border: none;
    cursor: pointer;
}
.tablaContainer {
    
}
