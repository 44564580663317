.freezersContainer {
    /* border: solid red 1px; */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-top: 1vw;
    padding-bottom: 2vw;

}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    /* border: solid red 1px; */
    height: 3vw;
}
.data{
    font-family: var(--font1);
    font-weight: normal;
}
.createFreezerButton {
    border: 0.15vw solid #00ABC8;
    background-color: white;
    border-radius: 2vw;
    color: #00ABC8;
    font-size: 0.9vw;
    font-weight: normal;
    width: 10%;
    height: 2vw;
    cursor: pointer;

    margin: auto;
    margin-top: 2vw;
}
.refButton{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.15vw solid #00ABC8;
    background-color: white;
    border-radius: 2vw;
    color: #00ABC8;
    font-size: 0.9vw;
    width: 100%;
    height: 1.5vw;
    cursor: pointer;
    margin: auto;
    
    
}

/* inpus de la tabla */
.inputName{
    border:none;
    border-bottom: solid grey 2px;
    text-align: center;
    font-size: 1vw;
    width: 50%;  
    color: var(--blue); 
}
.temp_range_Select{
    border:none;
    border-bottom: solid grey 2px;
    text-align: center;
    font-size: 1vw;
    width: 50%;   
}
.assigned_to_Select{
    border:none;
    border-bottom: solid grey 2px;
    text-align: center;
    font-size: 1vw;
    width: 50%;   
}
.createForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    /* border: solid red 1px; */
}



/* inputs del modal */
.inputsContainer {
    display: flex;
    flex-direction: column;
    width: 75%;
    /* border: solid green 1px; */
}

.nameInput {
    border-radius: 2vw;
    text-align: start;
    height: 1.5vw;
    margin-top: 5%
}



.select_range {
    border-radius: 1.5vw;
    height: 1.5vw;
    margin-top: 5%
}

.select_assigned {
    border-radius: 1.5vw;
    height: 1.5vw;
    margin-top: 5%
}

/* buttons */
.modal_buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10%;
    /* border: solid blue 1px; */
}

.createButton {
    border: 0.15vw solid #00ABC8;
    background-color: white;
    border-radius: 2vw;
    color: #00ABC8;
    font-size: 0.9vw;
    font-weight: normal;
    width: 25%;
    height: 1.5vw;
    cursor: pointer;

    margin: auto;

}

.cancelButton {
    border: 0.15vw solid #D60707;
    background-color: white;
    color: #D60707;
    border-radius: 2vw;
    font-size: 0.9vw;
    font-weight: normal;
    width: 25%;
    height: 1.5vw;
    cursor: pointer;
    margin: auto;

}