.container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 1%;
    //border: 0.15vw solid var(--cyan);
    border-radius: 1vw;
    width: 70%;
    background-color:var(--white);
    padding-top: 20px;   
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.title{
     //border:solid red 1px;
    width:95%;
    font-size: var(--h2);
    color: var(--blue);
    font-family: var(--font1);
    margin-left:30px;
}
.causes_branches_container{
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 85%;
}
.causes_branches_container2{
    display: flex;
    justify-content: space-around;
   // border: solid red 1px;
    width: 100%;
    height: 80%;
    padding-top:10px;
    position: relative;
    top: 0.5vw;
}
@media (max-width: 800px) {
    .container {
        width: 75vw;
        display: flex;
        flex-wrap: wrap;
        height: 80vw;
        margin: 2%;
        justify-content: center;
    }
}
