.icon {
    max-width: 3.3vh;
    max-height: 2vw;
    color: #1B1464;
}

.sideBarContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 6%;
    margin: 1%;
    margin-top: 4vw;
    padding: 0px !important;
}

.button {
    background-color: #F0F5F8;
    border: 0.15vw solid #F0F5F8;
    border-left: 4px solid #00ABC8;
    width: 2vw;
    height: 2.8vw;
    margin-bottom: 1vw;
    cursor: pointer;
   
}
.buttonActive {
    width: 100%;
    height: 4.7vw;
    background-color: #00ABC8;
    border: 0.15vw solid #00ABC8;
    border-radius: 0.5vw;
    margin-bottom: 1vw;
    //border:solid green 1px
}

.ai{
    background-color: #F0F5F8;
    border: 0.15vw solid #F0F5F8;
    border-left: 4px solid #9f9f9f;
    width: 2vw;
    height: 2.8vw;
    margin-bottom: 1vw;
   // cursor: pointer; 
}
.ai:disabled{
    background-color: #F0F5F8;
    border: 0.15vw solid #F0F5F8;
    border-left: 4px solid #9f9f9f;
    width: 2vw;
    height: 2.8vw;
    margin-bottom: 1vw;
   // cursor: pointer; 
}
.container {
    width: 100%;
    display: flex;

}



.buttonTitle {
    color: white;
    font-size: 0.8vw;
    font-family: var(--font1);
    text-decoration: none;

}

.buttonContainer {
    width: 100%;
    display: flex;
   // border: solid red 1px;
}

.respMenu {
    display: none;
}
.alertIcon {
    color:var(--orange);
    height: 1.5vw;
    width: 1.5vw;

}
.alertIconActive{
    color:var(--orange);
    height: 1.5vw;
    width: 1.5vw;
    position: absolute;
    left: 5vw;
   // background-color: white;
}

@media (max-width:800px) {
    .sideBarContainer {
        display: none;
    }

    .respMenu {
        display: flex;
        color: #00ABC8;
        border: 0.15vw solid #00ABC8;
        border-radius: 2vw;
        background: white;
        cursor: pointer;
        margin-bottom: auto;
        margin-top: 1vw;
        margin-left: 1vw;
        width: 6vw;
        height: 6vw;
        align-items: center;
        justify-content: center;
    }

    .respMenuContainer {
        height: 8vw;
        width: 8vw;
    }

}