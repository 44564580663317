@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');;

.appContainer {
    font-family: 'DM Sans', sans-serif;
    display: flex;
    flex-direction: column;
    height: auto;
    /* padding-right: 0 !important; */

}
#root { 
    
    overflow: auto;
}
body {

    background-image: url(./isotipo.jpeg);
    background-size: cover;
    margin: 0;
    background-repeat: no-repeat;
    /* padding-right: 0 !important; */
     min-height: 100%;
   /* border: solid red 1px; */
/* background-color: #eeeeee; */
font-family: 'DM Sans', sans-serif;

}