.barContainer {
    color: var(--blue);
    width: 30%;
    padding: 1%;
    // height: 30vw;

    position: relative;
    right: 2vw;
}

.bar {
    margin-top: 0.4vw;
    margin-bottom: 1vw;
    //border: 1px solid red;
}

.title {
    font-family: var(--font1);
    // margin-top: 2vw;
    margin-bottom: 1vw;
    font-size: var(--h3);
    //font-weight: normal;
}

.barTitle {
    font-family: var(--font1);
    font-size: var(--h4);
}

.loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75%; /* Full viewport height */
  }

.noData {
    font-size: var(--h4);
    margin-top: 25%;
}

@media (max-width: 800px) {
    .barContainer {
        width: 30%;
    }

    .title {
        font-size: 4vw;
    }

    .barTitle {
        font-size: 2.5vw;
    }

    .bar {
        margin-bottom: 2vw;
    }

    .noData {
        font-size: 3vw;
    }
}
//dash nuevo-----------------------------------------------
.barContainer2 {
    color: var(--blue);
    width: 30%;
    //padding: 1%;
    // height: 30vw;

    position: relative;
    //border:solid rgb(38, 211, 4) 1px;
}

.bar2 {
    margin-top: 0.4vw;
    margin-bottom: 1vw;
    //   border:solid rgb(38, 211, 4) 1px;
}

.title2 {
    // margin-top: 2vw;
    margin-bottom: 1vw;
    font-size: var(--h3);
    //font-weight: normal;
    font-family: var(--font1);
}

.barTitle2 {
    font-size: var(--h4);
    font-family: var(--font1);
}

.loading2 {
    margin-left: 20%;
    margin-top: 40%;
}

.noData2 {
    font-size: var(--h4);
    margin-top: 25%;
}
