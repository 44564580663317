.circle {
    background: #00ABC8;
    padding: 10%;
    border-radius: 50%;
    //display:block;
    width: 50px;
    height: 50px;
    //height: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-family: var(--font1);
    font-size: 2.5vw;
  //  margin-top: 5.5%;
    margin:auto;
}

.icon{
    max-width: 20%;
    max-height: 10vw;
    border: none;
}

.title{
    font-family: var(--font1);
    font-size:var(--h2);
  // font-weight:bolder;
    margin-left: 10%;
    color: var(--blue);
}


.card{
    width: 100%;
    height: 22vh;
    margin: 0.5%;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    padding: 0.2%;
    position: relative;
    top:1%;
    left:0.5%

}

.titleContainer{
    /* height: 55px; */
    height: 2.5vw;
    width: 90%;
    display: flex;
    align-items: center;
    margin-left: 10%;
    /* border: 2px solid red; */
}


.loading{
    display:flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 60px; */
    margin-top: 10%;
    margin: auto;
}
.noShipmetsInTransit{
   // border: solid red 1px;
    position: relative;
    top: -20%;
    display:flex;
    justify-content: center;

    margin: auto;
    font-size: var(--h2);
    color: var(--blue);

}
@media (max-width:800px){
    .card{
        width: 40vw;
        height: 22vw;
        margin: 1%;
      }

      .icon{
          max-width: 5vw;
          max-height: 5vw;
      }

      .title{
          font-size: 4vw;
      }

      .titleContainer{
          margin-top: 2vw;
      }

      .circle{
          padding: 10%;
          height: 5vw;
          width: 15%;
          font-size: 4vw;
      }

      .percentNeg{
        font-size: 3.3vw;
      }
      
      .percentPos{
        font-size: 3.3vw;
      }

      .percentText{
        font-size: 2vw;
    }
}

