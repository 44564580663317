.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  
  
    
}

.eachSection{
    display: flex;
    /* align-items: flex-start; */
    flex-direction: column;
    width: 100%;
    margin-bottom: 1vw;
    justify-content: flex-start;
    
    
}
.select_container{  
    display: flex;
    
}
.select_section{
    display: flex;
    /* align-items: flex-start; */
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
   
    height: "3vw";
   
}

.eachInput{
    margin-top: 1%;
    width: 95%;
    height: 0.8vw;
    
    border-radius: 2vw;
    text-align: start;
    padding: 1.5%;
  
}

.eachLabel{
    text-align: start;
    color: #1B1464;
    font-size: 1.1vw;
    
}


.createButton{
        border: 0.15vw solid #00ABC8;
        background-color: white;
        border-radius: 2vw;
        color: #00ABC8;
        font-size: 1vw;
        font-weight: normal;
        width: 40%;
        cursor: pointer;
        margin-top: 1vw;
        margin-bottom: 1vw;
        margin-left: 30%;
        padding: 1%;
}

.createButton:disabled,
button[disabled]{
  border: 0.15vw solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor:not-allowed;
}

.createTitle{
    margin-bottom: 1.2vw; 
    font-size: 1.5vw;
    margin-left: 0px !important; 
}

.select{
    margin-top: 1%;
    font-family: 'Quattrocento Sans', sans-serif;
   
    font-size: 1vw;
    border-radius: 2vw;
   /*  margin-left: auto; */
    width: 13.7vw;
   
}