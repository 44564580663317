html {
  background: #fafafa;
  font-size: 14px;
  width: 100%;
  height: 100%;
}

.App {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.42857;
  text-rendering: optimizeLegibility;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dropdownContainer {
  height: 50vw;
  border: solid red 1px;
}

.icon {
  cursor: pointer;
}