@import "../../global.scss"; // Importar variables SASS

.cardsContainer {
    width: 100%;
    display: inline-flex;
    // justify-content: space-between;
    margin: auto;
    height: 20vw; //25
    // border: solid 1px rgb(0, 79, 190);
 
}
.reportsContainer {
    width: 98%;

    margin: auto;
    // height: 25vw;
    padding: 1%;
}
.nav {
    display: flex;
   // justify-content: flex-end;

    width: 100%;
    margin-bottom: 0.5vw;
    margin-top: 2vw;
   // border:solid red 1px;
    align-items: center;
    margin-left: 45px;
    position: relative;
    left: -45px;
}



.allContainer {
    width: 99%;
    /* max-width: calc(100% - 50px); */
    display: flex;
    flex-direction: column;
   // border: solid red 1px;
    position: relative;
    top: -35px;
}
.sarasa {
    font-family: var(--font1);
    font-size: var(--h1);
    color: var(--red);
    font-weight: var(--w1);
}

@media (max-width: 800px) {
    .cardsContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .initialFilterButton {
        width: 10%;
        height: 4vw;
        font-size: 1.5vw;
    }

    .initialFilterButtonSelected {
        width: 10%;
        height: 4vw;
        font-size: 1.5vw;
    }
}

//dasboard 2
.cardTitle {
    font-family: var(--font1);
    font-size: var(--h3);
    color: var(--blue);
  //  font-weight: var(--w1);
     position:relative;
    top: 1vw;
    left: 68px;
   
}
.cardsContainer2 {
    width: 100%;
    display: inline-flex;
    // justify-content: space-between;
    margin: auto;
    height: 20vw; //25
   //  border: solid 1px rgb(0, 79, 190);
 
}