.container {
    width: 100%;
    padding: 0.5%;
    display: flex;
    flex-direction: column;
    /* border: solid red 1px; */
}
.pickerContainer {
    //  border: solid red 1px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 7%;
}
.tableContainer {
    /* border: solid red 1px; */
    display: flex;
    width: 97%;
    flex-direction: column;
    /* border: solid green 1px; */
    margin-left: 3%;
}

.headerContainer {
    width: 97%;
    display: flex;
    position: relative;
    justify-content: space-between;
    border-bottom: var(--borderBottom);
    //  border: solid red 1px;  
     background-color: var(--white);
     height: 3vw;
}

.header_item {
    display: flex;
    justify-content: center;
    align-items: center;
    //  border: solid black 1px; 
    color: var(--blue);
    font-size: var(--h4);
    font-family: var(--font1);

    height: 3vw;
}

.header_item_name {
    display: flex;
    align-items: center;
    
//    border: solid black 1px; 
    color: var(--blue);
    font-size: var(--h4);
    font-family: var(--font1);
    height: 3vw;
    padding-left: 2%;
}
.iconContainer{
    border: none;
    width: 1vw;
    height: 1vw;
    position: relative;
   

}
.header_item_reports {
    display: flex;
    justify-content: right;
    align-items: center;
    /* border: solid black 1px; */
    color: var(--cyan);
    font-size: var(--h31);
    
    height: 2vw;
    padding-right: 2%;
}

.title {
    color: var(--blue);
    display: flex;
    font-size: var(--h1);
    font-family: var(--font1);
    margin-left: 44.5%;
    margin-bottom: 1%;
    width: 10%;

    /* border: solid black 1px; */
}

.searchParamstext {
    margin-top: 4vw;
    width: 98%;
    text-align: center;
    font-size: var(--h2);
    color: var(--blue);
    padding: 1%;
    font-family: var(--font1);
    font-weight: normal;
}

.formContainer {
    height: 100%;
//    border: solid black 1px; 
    display: flex;
    height: 30%;
    justify-content: center;
    margin-bottom: 1%;
    /* align-items: center; */
    /* flex-direction: row; 
    border: solid red 1px;
    justify-content: right; */
    /* padding-left: 8%; */
}

.filter {
    display: flex;
    /* flex-direction: column; */
    /* border: solid rgb(13, 216, 81) 1px; */
    width: 70%;
    justify-content: center;
    align-items: center;
}

.filterContainer {
    /* border: solid red 1px; */
}

.select_from_to {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 0.15vw solid var(--cyan);
    border-radius: 2vw;
    height: 1.4vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
    width: 8vw;
}

input[type="date"]::before {
    color: var(--blue);
    content: attr(placeholder);
    margin-left: 2%;
    width: 100%;
    padding: 3%;
    font-size: var(--h4);
    display: inline-block;
}

input[type="date"]:focus::before {
    content: "" !important;
    display: none;
    position: relative;
}

.desde_hasta {
    /* border: solid red 1px; */
    width: 10%;
    display: flex;
    flex-direction: column;
}

.estado_codBarras_qr_id {
    /* border: solid red 1px; */
    width: 90%;
    display: flex;
    /* align-items: center; */
}

.select_qr {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 0.10vw solid var(--blue);
    border-radius: .5vw;
    height: 1.5vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
    width: 8vw;
}

.searchButtonContainer {
    width: 15%;
    display: flex;
    /* margin-top: 1vw; */
    height: 4vw;
    justify-content: center;
    /* border: solid red 1px; */
    align-items: center;
    margin-left: 1%;
}

.pagination {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 1vw;
    margin-bottom: 1vw;
    height: 2vw;
}

.dataContainer {
    width: 100%;
    position: relative;
    /* left:3%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.data {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--blue);
    font-size: var(--h4);
    /* border: solid red 1px; */
    height: 2.7vw;
}

.data_name {
    display: flex;
    justify-content: left;

    align-items: center;
    color: var(--blue);
    font-size: var(--h4);
    /* border: solid red 1px; */
    height: 2.7vw;
    padding-left: 2%;
}
.data_name_icon{
    display: flex;
    justify-content: left;
    align-items: center;
    color: var(--blue);
    font-size: var(--h4);
    height: 2.7vw;
    padding-left: 1%;
}

.data_reports {
    display: flex;
    justify-content: right;
    align-items: center;
    color: var(--blue);
    font-size: var(--h4);
    /* border: solid red 1px; */
    height: 2.7vw;
    padding-right: 2%;
}

.data_status {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--blue);
    font-size: var(--h4);
    /* border: solid red 1px; */
    height: 2.7vw;
    cursor: pointer;
    transition: box-shadow 0.3s;
    border-radius: 5px;
}

.data_status:hover {
    box-shadow: 0 0 0.1vw 0.1vw #b9b9b9;
    /* background-color: #dcdcdc; */
}
.data_wifi {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--blue);
    font-size: var(--h4);
    /* border: solid red 1px; */
    height: 2.7vw;
    cursor: pointer;
}

.rombo {
    width: 2vw;
}

.searchButton {
    background-color: var(--cyan);
    border: 0.15vw solid var(--cyan);
    color: var(--white);
    height: 2vw;
    border-radius: .5vw;
    width: 100%;
    font-size: var(--h3);
    font-family: var(--font1);
    cursor: pointer;
    //margin-top: 0.5vw;
}

.form_from_to_button {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    color: var(--blue);
    font-size: var(--h4);
    height: 5vw;
    /* border: solid rgb(255, 0, 212) 1px; */
}

.from_to_container {
    display: flex;
    align-items: center !important;
    width: 95%;
    justify-content: flex-start;
    //border: solid rgb(255, 0, 212) 1px;
    padding-left: 5%;
}

.filter_and_comments_container {
    width: 30%;
   // border: solid red 1px;
}

/* .buttonContainer{
    border: solid red 1px;  
    display: flex;
    width: 50%;
    height: 2vw;
    justify-content: center;
    margin:auto;
} */
.searchButton2 {
    background-color: var(--cyan);
    border: 0.15vw solid var(--cyan);
    color: var(--white);
    height: 1.9vw;
    border-radius: 2vw;
    width: 6vw;
    font-size: var(--h4);
    font-family: var(--font1);
    cursor: pointer;
}

.searchAndDataContainer {
    display: flex;
    height: 100%;
    margin-top: 0.1vw;
    /* border: solid rgb(26, 255, 0) 1px; */
}

.freezeDropdownContainer {
    height: 75%;
    width: 100%;
    /* border: solid rgb(26, 255, 0) 1px; */
    background-color: var(--white);
}

.freezeTempGraphContainer {
   // border: solid red 1px; 
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.buttonCsvDownload {
    background: var(--transparent);
    border: none;
    cursor: pointer;
    z-index: 100;
    position: relative;
    left: -1.5vw;
    /* border: solid red 1px; */
    top: 0;
}

.icon {
    max-width: 3.3vh;
    max-height: 2vw;
    /* color: var(--blue); */
    /* border: solid red 1px; */
}
.wifi_icon {
    width: 2vw;
}
