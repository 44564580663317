.usersContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;

}



.editUserButton {
    border: 0.15vw solid  var(--cyan);
    background-color:  var(--white);
    border-radius: 1vw;
    color:  var(--cyan);
    font-size:  var(--h5);
   // font-weight: normal;
    width: 8vw;
    cursor: pointer;
    /* padding: 1.5%; */
    margin-left: "3vw";
}



.deleteUserButton {
    border: 0.15vw solid var(--red);
    background-color: var(--white);
    color: var(--red);
    border-radius: 1vw;
    font-size:  var(--h5);
    //font-weight: normal;
    width: 8vw;
    cursor: pointer;
    /* padding: 1.5%; */
}

.cancelButton {
    border: 0.15vw solid var(--red);
    background-color:  var(--white);
    color: var(--red);
    border-radius: 1vw;
    font-size:  var(--h5);
   // font-weight: normal;
    width: 8vw;
    cursor: pointer;

    /* padding: 1.5%; */
}




.buttonsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    /* border: solid green 1px */
}

.buttonsContainer2 {
    display: flex;
    flex-direction: column;
    border: solid var(--red) 1px
}


.assignUserInput { 
    margin-left: 2%;
    position:relative;
    top:0.5vw;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-radius: 2vw;
    padding: 1.5%;
    font-size: var(--h4);
    font-family: var(--font1);
}


.pagination {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left: auto;
    margin-top: 1vw;
}

.dropdown_type_div {

    display: flex;
    flex-direction: row;
    justify-content: center;

}

.dropdownButton {

    background-color: var(--transparent);
    border: none;
    cursor: pointer;
    color:  var(--cyan);

}

.searchQrLabel {
    margin-right: 0.5vw;
    margin-top: 0.3vw;
}

.searchQrInput {
    border-radius: 1.6vw;
    border-color: #D9F1F5;
    padding: 2%;
    font-family: var(--font1) !important;
    font-size: var(--h4);
    width: 90%;
}


.inputContainer {
    margin-left: auto;
    display: flex;
    flex-direction: row;
}

.noUsers {
    width: 70%;
    color: var(--blue);
    font-size: 25px;
    margin-left: 100%;
    margin-top: 20%;
    margin-bottom: 20%;
    /* border: 2px solid red; */
}

.noUsersFromStart {
    width: 40%;
    color: var(--blue);
    font-size: var(--h11);
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.loading {
    padding: 3%;
}

.select {
    font-family: var(--font1);
    padding: 2%;
    font-size: var(--h4);
    border-radius: 2vw;
}

.typeToShow {
    /* color: red;
    border: solid red 1px; */
    position: relative;
    left: 3.5vw;
    text-align: left;
}

.emailToShow {
    /* color: red;
    border: solid red 1px; */
    text-align: left;
}

