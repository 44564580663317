.container {
    // width: 95%;
    padding: 0.5%;
    display: flex;
    flex-direction: column;
    //border: 0.15vw solid var(--cyan);
    border-radius: 1vw;
    background-color: var(--white);
    //margin-top: 2%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.title {
    font-family: var(--font1);
    color: var(--blue);
    font-size: var(--h2);
    margin-top: 1%;
    margin-left: 1.5%;
}

.formContainer {
    height: 100%;

    display: flex;
    /* flex-direction: row; 
    border: solid red 1px; */
    justify-content: center;
    //padding-left: 8%;
}

.filter {
    width: 90%;
    //border: solid black 1px; 
    display: flex;
    justify-content: center;
    position: relative;
    left: 3vw;
}

.select_from_to {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 0.15vw solid var(--cyan);
    border-radius: 2vw;
    height: 1.4vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
}


input[ id=from_to]::before {
    color: var(--blue);
    content: attr(placeholder);
    margin-left: 2%;
    margin-top: 20%;
    width: 100%;
    padding: 3%;
    /* margin-bottom: 3vw; */
    font-size: var(--h4);
    // /* color: #aaa;
    display: inline-block;


}


input[ id=from_to]:focus::before {
    content: "" !important;
    display: none;
    position: relative;

}

.origen_destino {
    width: 15%;
    display: flex;
    flex-direction: column;
}

.destinatario_remitente {
    /* border: solid red 1px; */
    width: 15%;
    display: flex;
    flex-direction: column;
    /* position: relative;
    left: 20% */
}

.desde_hasta {
    /* border: solid red 1px; */
    width: 10%;
    display: flex;
    flex-direction: column;
}

.estado_codBarras_qr_id {

    width: 60%;
    height: 6vw;
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */

}

.select {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 0.1vw solid var(--blue);
    border-radius: .5vw;
    height: 2vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
}

.select_status {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 0.10vw solid var(--blue);
    border-radius: .5vw;
    height: 2vw;
    color: var(--blue);
    padding: 0.15vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    width: 20%;
}

.select_barcode {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 0.10vw solid var(--blue);
    border-radius: .5vw;
    height: 1.5vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
    width: 20%;
}

.select_qr {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 0.10vw solid var(--blue);
    border-radius: .5vw;
    height: 1.5vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
    width: 20%;
}

.select_id {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 0.10vw solid var(--blue);
    border-radius: .5vw;
    height: 1.5vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
    width: 20%;
}

.select_branch {
    font-family: var(--font1);
    font-size: 1vw;
    border: 0.10vw solid var(--blue);
    border-radius: .5vw;
    height: 2vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
    width: 20%;
}

.select_operator {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 0.10vw solid var(--blue);
    border-radius: .5vw;
    height: 2vw;
    color: var(--blue);
    padding: 0.15vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    width: 21%;
}

.select_qr::placeholder {
    //font-weight: normal;
    color: var(--blue);
    margin-left: 80%;
}

.select_barcode::placeholder {
    //font-weight: normal;
    color: var(--blue);
    position: relative;
    right: 20%;

    // margin-left: 55%;
}


.select_id::placeholder {
    //font-weight: normal;
    color: var(--blue);
    margin-left: 80%;
}

.pagination {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 1vw;
    margin-bottom: 1vw;
    height: 2vw;
}

.button {
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 100;
    //border:solid red 1px;
    width: 60px;
}

.button:disabled {
    background: transparent;
    border: none;
    cursor: not-allowed;
    z-index: 100;
    //border:solid red 1px
}

.button_selected_interval_text {
    //border:solid red 1px;
    position: relative;
    left: 5px
}

.filterTitle {

    margin: auto;
    font-size: var(--h4);


}



.option {
    border-radius: 2vw;
}

.searchButton {
    background-color: var(--cyan);
    border: 0.15vw solid var(--cyan);
    color: var(--white);
    height: 2vw;
    border-radius: .5vw;
    width: 10%;
    font-size: var(--h3);
    font-family: var(--font1);
    cursor: pointer;
    //margin-top: 0.5vw;
}

.searchButtonContainer {
    width: 100%;
    display: flex;
    margin-top: 1vw;
    justify-content: center;
    //  border:solid red 1px; 
    align-items: center;
    align-items: center;
}

.message1 {

    width: 98%;
    text-align: center;
    font-size: var(--h4);
    color: var(--blue);
    position: relative;
    top: -10px;
    font-family: var(--font1);
}

.message2 {
    margin-left: 40%;
    margin-top: 3vw;
    font-size: 2vw;
    color: var(--blue);
    padding: 1%;
    font-family: var(--font1);
}

.loading {
    margin-left: 45%;
    margin-top: 5vw;
}



.commentsButton {
    color: var(--blue);
    background-color: var(--transparent);
    border: none;
    font-size: var(--h4);
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
}





.checkboxContainer {
    display: flex;
    flex-direction: row;
    /* border: solid red 1px; */
    width: 3.5vw;
    justify-content: space-between;
}

.ClearButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    position: relative;
    top: 5%;
}

.clearButton {

    position: relative;
    background-color: var(--white);
    border: solid 0.1vw var(--red);
    border-radius: .5vw;
    /* font-size: 16px; */
    color: var(--red);
    font-size: var(--h4);

    font-family: var(--font1);

    cursor: pointer;
    cursor: pointer;

    height: 2vw;
    width: 7.5vw;
    align-items: center;
    justify-content: center;
    /* Safari */
}

.tableContainer {
    /* border: solid red 1px; */
    display: flex;
    width: 100%;
    flex-direction: column;

}

.headerContainer {
    width: 97%;
    display: flex;
    position: relative;
    left: 1.6%;
    justify-content: space-between;
    border-bottom: var(--borderBottom);
    /* border: solid red 1px;  */

}

.header_item {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: solid black 1px; */
    color: var(--blue);
    font-size: var(--h4);
    font-family: var(--font1);

    height: 2vw;

}

.check_data_container {
    /* border: solid rgb(37, 77, 143) 1px; */
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;

}

.checkbox {
    /* border: solid red 1px;  */
    display: flex;
    height: 3vw;
}

.dataContainer {

    width: 100%;
    position: relative;
    /* left:3%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // border: solid red 1px;

}

.data {
    display: flex;
    justify-content: space-evenly;
    font-family: var(--font1);
    align-items: center;
    color: var(--blue);
    font-size: var(--h4);
    text-align: center;
    // border: solid red 1px;

}

.downloadContainer {
    display: flex;
    // justify-content: space-around;
    align-items: center;
    color: var(--blue);
    font-size: var(--h4);
    //  border: solid rgb(0, 81, 255) 1px; 
    // position:relative;
    // left: 2%;
    padding-left: 1%;
}

.rombo {
    width: 1.5vw;

    /* position: relative;
    left: 0.5vw */
}

.error_text {
    display: flex;
    color: var(--red);
    justify-content: center;
    align-items: center;
    /* margin-top: 2%; */
    height: 2vw;

}
.Toastify__toast-container {
    position: fixed;
    z-index: 9999; /* Asegúrate de que este valor sea mayor que cualquier otro en tu aplicación */
    top: 1rem; /* Ajusta la distancia desde la parte superior de la pantalla */
    right: 1rem; /* Ajusta la distancia desde el borde derecho de la pantalla */
  }
  
  .Toastify__toast {
    margin-bottom: 0.5rem; /* Ajusta el espaciado entre toasts */
  }
//dash nuevo-----------------------------------------------------------------

.container2 {
    // width: 95%;
    padding: 0.5%;
    display: flex;
    flex-direction: column;
    // border: 0.15vw solid var(--cyan);
    //border-radius: 2vw;
    background-color: var(--white);
    font-family: var(--font1);
    height: 100%;
    margin-top: 2%;
}

.title2 {
    color: var(--blue);
    font-size: var(--h2);
    margin-top: 1%;
    margin-left: 1.5%;
    font-family: var(--font1);
}

.formContainer2 {
    height: 100%;

    display: flex;
    /* flex-direction: row; 
     border: solid red 1px; */
    justify-content: center;
    //padding-left: 8%;
}

.filter2 {
    width: 90%;
    //border: solid black 1px; 
    display: flex;
    justify-content: center;
    position: relative;
    left: 3vw;
}

.select_from_to2 {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 0.15vw solid var(--cyan);
    border-radius: 2vw;
    height: 1.4vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
}


input[ id=from_to]::before {
    color: var(--blue);
    content: attr(placeholder);
    margin-left: 2%;
    margin-top: 20%;
    width: 100%;
    padding: 3%;
    /* margin-bottom: 3vw; */
    font-size: var(--h4);
    // /* color: #aaa;
    display: inline-block;


}


input[ id=from_to]:focus::before {
    content: "" !important;
    display: none;
    position: relative;

}

.origen_destino2 {
    /* border: solid red 1px; */
    width: 15%;
    display: flex;
    flex-direction: column;
}

.destinatario_remitente2 {
    /* border: solid red 1px; */
    width: 15%;
    display: flex;
    flex-direction: column;
    /* position: relative;
     left: 20% */
}

.desde_hasta2 {
    /* border: solid red 1px; */
    width: 10%;
    display: flex;
    flex-direction: column;
}

.estado_codBarras_qr_id2 {

    width: 60%;
    height: 6vw;
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */

}

.select2 {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 1px solid var(--disabled);
    border-radius: 5px;
    height: 2vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
}

.select_status2 {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 1px solid var(--disabled);
    border-radius: 5px;
    height: 2vw;
    color: var(--blue);
    padding: 0.15vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    width: 20%;
}

.select_barcode2 {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 1px solid var(--disabled);
    border-radius: 5px;
    height: 1.4vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
    width: 20%;
}

.select_qr2 {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 1px solid var(--disabled);
    border-radius: 5px;
    height: 1.4vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
    width: 20%;
}

.select_id2 {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 1px solid var(--disabled);
    border-radius: 5px;
    height: 1.4vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
    width: 20%;
}

.select_branch2 {
    font-family: var(--font1);
    font-size: 1vw;
    border: 1px solid var(--disabled);
    border-radius: 5px;
    height: 2vw;
    color: var(--blue);
    padding: 0.15vw;
    margin: 0.5vw;
    width: 20%;
}

.select_operator2 {
    font-family: var(--font1);
    font-size: var(--h4);
    border: 1px solid var(--disabled);
    border-radius: 5px;
    height: 2vw;
    color: var(--blue);
    padding: 0.15vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    width: 21%;
}

.select_qr2::placeholder {
    //font-weight: normal;
    color: var(--blue);
    margin-left: 80%;
}

.select_barcode2::placeholder {
    //font-weight: normal;
    color: var(--blue);
    margin-left: 55%;
}


.select_id2::placeholder {
    //font-weight: normal;
    color: var(--blue);
    margin-left: 80%;
}

.pagination2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 1vw;
    margin-bottom: 1vw;
    height: 2vw;
}

.button2 {
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 100;
}

.filterTitle2 {

    margin: auto;
    font-size: var(--h4);


}



.option2 {
    border-radius: 2vw;
}

.searchButton2 {
    background-color: var(--blue);
    border: 0.15vw solid var(--blue);
    color: var(--white);
    height: 2vw;
    border-radius: 5px;
    width: 10%;
    font-size: var(--h31);
    font-family: var(--font1);
    cursor: pointer;

}

.searchButtonContainer2 {
    width: 100%;
    display: flex;
    margin-top: 1vw;
    justify-content: center;
    // border:solid red 1px;
    align-items: center;
}

.message12 {

    width: 98%;
    text-align: center;
    font-size: var(--h4);
    color: var(--blue);
    position: relative;
    top: -10px;
    font-family: var(--font1);
}

.message22 {
    margin-left: 40%;
    margin-top: 3vw;
    font-size: 2vw;
    color: var(--blue);
    padding: 1%;
    font-family: var(--font1);
}

.loading2 {
    margin-left: 45%;
    margin-top: 5vw;
}

.commentsButton2 {
    color: var(--blue);
    background-color: var(--transparent);
    border: none;
    font-size: var(--h4);
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
}

.checkboxContainer2 {
    display: flex;
    flex-direction: row;
    /* border: solid red 1px; */
    width: 3.5vw;
    justify-content: space-between;
}

.ClearButtonContainer2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    position: relative;
    top: 5%;
}

.clearButton2 {

    position: relative;
    background-color: var(--red);
    border: solid 0.2vw var(--red);
    border-radius: 5px;
    /* font-size: 16px; */
    color: var(--white);
    font-size: var(--h4);

    font-family: var(--font1);

    cursor: pointer;
    cursor: pointer;

    height: 2vw;
    width: 7.5vw;
    align-items: center;
    justify-content: center;
    /* Safari */
}

.tableContainer2 {
    /* border: solid red 1px; */
    display: flex;
    width: 100%;
    flex-direction: column;

}

.headerContainer2 {
    width: 97%;
    display: flex;
    position: relative;
    left: 1.6%;
    justify-content: space-between;
    border-bottom: var(--borderBottom);
    /* border: solid red 1px;  */

}

.header_item2 {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: solid black 1px; */
    color: var(--blue);
    font-size: var(--h31);

    height: 2vw;

}

.check_data_container2 {
    /* border: solid rgb(37, 77, 143) 1px; */
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;

}

.checkbox2 {
    /* border: solid red 1px;  */
    display: flex;
    height: 3vw;
}

.dataContainer2 {

    width: 100%;
    position: relative;
    /* left:3%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // border: solid red 1px;

}

.data2 {
    display: flex;
    justify-content: center;

    align-items: center;
    color: var(--blue);
    font-size: var(--h4);
    text-align: center;

}

.downloadContainer2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--blue);

    font-size: var(--h4);
    //  /* border: solid rgb(0, 81, 255) 1px; 
}

.rombo2 {
    width: 1.5vw;

    /* position: relative;
     left: 0.5vw */
}

.error_text2 {
    display: flex;
    color: var(--red);
    justify-content: center;
    align-items: center;
    /* margin-top: 2%; */
    height: 2vw;

}