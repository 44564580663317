.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading{
    margin-left: 45%;
    margin-top: 60%;
}

.eachComment{
    font-size: 20px;
    /* align-items: center;
    justify-content: center; */
    border: 2px solid #1B1464;
    background-color: #F0F5F8 ;
    border-radius: 20px;
    padding: 5px;
    margin: 10px;
    height: auto;
    overflow: hidden;
}

.commentsContainer{
    width: 90%;
}

.title {
    color: #1B1464;
    font-size: 25px;
}

.noComments{
    font-size: 18px;
    margin-top: 40%;
    margin-left: 12%;
}

.author{
    font-size: 15px;
}

.input{
    height: 100px;
    width: 90%;
    overflow-y: auto;
    white-space: initial;
}

.button{
    border: 2px solid #00ABC8;
    background-color: white;
    border-radius: 2px;
    color: black;
    border-radius: 20px;
    font-size: 14px;
    font-weight: normal;
    width: 130px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 5px;
    padding: 4px;
}

