.container{
    display: flex;
    flex-direction: column;
    margin-top: 1vw;
    width: 88vw;
    margin-bottom: 1vw;
}

.createUserButton{
    border: 0.15vw solid #00ABC8;
    background-color: white;
    border-radius: 2vw;
    color: #00ABC8;
    font-size: 1vw;
    font-weight: normal;
    width: 12%;
    cursor: pointer;
    padding: 0.3%;
    margin-top: 1vw;
    margin-bottom: 1vw;
}

.createDeviceButton{
    border: 0.15vw solid #00ABC8;
    background-color: white;
    border-radius: 2vw;
    color: #00ABC8;
    font-size: 1vw;
    font-weight: normal;
    width: 12%;
    cursor: pointer;
    padding: 0.5%;
    margin-top: 2vw;
    margin-bottom: 1vw;
    margin-left: 38vw;
}

.createDistributorButton{
    border: 0.15vw solid #00ABC8;
    background-color: white;
    border-radius: 2vw;
    color: #00ABC8;
    font-size: 1vw;
    font-weight: normal;
    width: 12%;
    cursor: pointer;
    padding: 0.3%;
    margin-top: 1vw;
    margin-bottom: 1vw;
}