.container {
    display: flex;
    //border: solid red 1px;
    flex-direction: column;
    width: 100%;
    height: 100%;
    // justify-content: center;
    /* align-items: center;
   
    /* background-color: #12d2e824; */
    // margin: 2% auto;
    //height: 20vw;
}

.fContainer {
    display: flex;
    flex-direction: column;
    //border: solid rgb(184, 1, 1) 1px;
    position: relative;
    top:4vw;
   // left: -15%;
    border-radius: 0.5vw;
    width: 90%;
    height: 27.5vw;
    font-family: var(--font1);
    font-weight: normal;
    /* background-color: #b30a0a; */
}

.f_commentsAndAddContainer {
  //  background-color: #851717;
    /* border: solid red 1px; */
    /* background-color: rgb(212, 3, 3); */
}

.f_commentsContainer {
    width: 100%;
    height: 22vw;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
    /* background-color: rgb(231, 64, 153); */
    overflow-y: auto;
    //border: solid rgb(0, 51, 255) 1px; 
}

/* Opcional: Estilos adicionales para la barra de desplazamiento */
.f_commentsContainer::-webkit-scrollbar {
    width: 10px; /* Ancho de la barra de desplazamiento */
}

.f_commentsContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey; /* Color del pulgar de la barra de desplazamiento */
    border-radius: 10px; /* Radio de borde para el pulgar */
}

.f_commentsContainer::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color de fondo de la pista */
}
.f_eachComment {
    display: flex;
    flex-direction: row;
    font-size: 1vw;
    justify-content: space-between;
    font-family: "Quattrocento Sans", sans-serif;
    /* border: 0.15vw solid #1B1464; */
    background-color: #00aac82f;
    color: #1b1464;
    /* border-radius: 2vw; */
    padding: 1%;
    margin-bottom: 1%;
    height: 3vw;
    overflow: auto;
    width: 100%;
}

.f_addCommentContainer {
    // border: solid rgb(115, 255, 0) 1px;
    background-color: #fafafa;
}
.f_loading{
    margin-left: 45%;
   margin-top:4.5vw; 
}
.loading {
    margin-left: 45%;
    margin-top: 9vw;
}

.eachComment {
    display: flex;
    flex-direction: row;
    font-size: 1vw;
    justify-content: space-between;
    font-family: "Quattrocento Sans", sans-serif;
    /* border: 0.15vw solid #1B1464; */
    background-color: #00aac82f;
    color: #1b1464;
    /* border-radius: 2vw; */
    padding: 1%;
    margin-bottom: 1%;
    height: 3vw;
    overflow: auto;
}

::-webkit-scrollbar {
    width: 0px;
    height: 2px;
    background-color: white;
}

.commentsContainer {
    width: 100%;
    min-height: 85%;
    align-items: center;
    justify-content: center;
   // border: solid rgb(212, 255, 0) 1px;
    // margin-left: 2%;
    overflow-y: auto;
    margin-top: 2%;
    margin-bottom: 3%;
}
/* Opcional: Estilos adicionales para la barra de desplazamiento */
.commentsContainer::-webkit-scrollbar {
    width: 10px; /* Ancho de la barra de desplazamiento */
}

.commentsContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey; /* Color del pulgar de la barra de desplazamiento */
    border-radius: 10px; /* Radio de borde para el pulgar */
}

.commentsContainer::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color de fondo de la pista */
}
.commentsAndAddContainer {
    display: flex;
    flex-direction: column;
    height: 80%;
    //  border: solid rgb(13, 94, 44) 1px;
}

.title {
    color: var(--blue);
    font-family: var(--font1);
    height: 1.5vw;
    text-align: center;
    background-color: darkgrey;
    color: var(--white);
    // margin-left: 2%;
    //border: solid red 1px;
    margin: 0;
    font-size: 1vw;
    padding: 2%;
    border-radius: 10px;
}

.f_title {
    color: var(--blue);
    font-size: 1.5vw;
    margin-left: 2%;
    font-weight: normal;
    text-align: left;
}

.noComments {
    font-size: 1vw;
    margin-top: 10%;
    text-align: center;
}

.text {
    width: 70%;
    height: auto;
    word-wrap: break-word !important;
 

    /* line-height: 1.5; */
}

.author {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    font-size: 0.8vw;
    color: black;
    // border:solid red 1px;
    position: relative;
    left: -10px;
}
.deleteButton_input_saveButtton_container {
    display: flex;
    justify-content: center;
    align-items: center;
    //width: 20%;
    //border: solid red 1px;
    //position: relative;
    // top: 20px;
    // width: 50%;
    border:none
}
.addCommentContainer {
   // margin-top: 3%;
    display: flex;
    width: 100%;
    justify-content: center;
    // height: 20%;
    align-items: center;
    // position: relative;
    // top: 50px;

    //border: solid rgb(11, 23, 184) 1px;
}
.deleteGraphComment {
    height: 2vw;
    width: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 2vw;
    left: 35vw;
    //border: solid red 1px;
    cursor: pointer;
    border: none;
}
.input {
    height: 3vw;
    width: 100%;
    overflow-y: auto;
    //  white-space: initial;
    // font-family: 'Quattrocento Sans', sans-serif;
    // font-size: 1vw;
    border: none;
    // border-bottom: solid 1px #6e6e6e;
    //padding-right: 5%;
}
.sendIcon {
    height: 1.5vw;
    width: 1.5vw;
    cursor: pointer;
    color: #00abc8;
    position: relative;
    left: -15%;
    // bottom: 0.5vw;
}
.fInput {
    height: 3vw;
    width: 99%;
    /* overflow-y: auto; */
    /* white-space: initial; */
    font-family: "Quattrocento Sans", sans-serif;
    font-size: 1vw;
    border: none;
    /* border: solid red 1px; */
    border-bottom: solid 1px #6e6e6e;
    /* margin-top: 5%; */
}

.buttonsContainer {
    width: 50%;
    display: flex;
    justify-content: space-around;
}

.f_buttonsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: solid red 1px; */
}

.button {
    border: 0.15vw solid #00abc8;
    background-color: white;
    color: #00abc8;
    border-radius: 2vw;
    font-size: 0.8vw;
    font-weight: normal;
    width: 40%;
    cursor: pointer;
    margin-top: 1vw;
    margin-bottom: 1vw;
    padding: 2%;
}

.fButton {
    border: 0.15vw solid #00abc8;
    background-color: white;
    color: #00abc8;
    border-radius: 2vw;
    font-size: 0.8vw;
    font-weight: normal;
    width: 40%;
    cursor: pointer;
    margin-top: 1vw;
    margin-bottom: 1vw;
    padding: 2%;
}

.cancelButton {
    border: 0.15vw solid #d60707;
    background-color: white;
    color: #d60707;
    border-radius: 2vw;
    font-size: 0.8vw;
    font-weight: normal;
    width: 40%;
    cursor: pointer;
    margin-top: 1vw;
    margin-bottom: 1vw;
    padding: 2%;
}

.addCommentbutton {
    border: 0.15vw solid #00abc8;
    background-color: white;
    color: #00abc8;
    border-radius: 2vw;
    font-size: 0.8vw;
    font-weight: normal;
    width: 40%;
    cursor: pointer;
    margin-top: 0.5vw;
    margin-bottom: 1vw;
    padding: 2%;
    margin-left: 30%;
}

/*comentarios de TableViajes */

.table_container {
    /* border: solid red 1px; */
    height: 100%;
}

.table_commentsAndAddContainer {
    /* border: solid green 1px; */
    height: 50%;
    overflow-y: auto;
}

.table_addCommentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* border: solid blue 1px; */
    margin-top: 5%;
}
/* -----------------alerts comments----------------- */
.alertCommentText_button_container {
    /* border: solid red 1px; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 50%;
}

.alertCommentText {
    /* margin-right: 5%; */
    width: 70%;
    height: 80%;
    font-size: 1vw;
    font-family: "Quattrocento Sans", sans-serif;
    border: none;
    border-bottom: solid 1px #6e6e6e;
}
.alertCommentButton {
    position: relative;
    right: -15%;
    top: -5%;
    border: 0.15vw solid #00abc8;
    background-color: white;
    color: #00abc8;
    border-radius: 2vw;
    font-size: 0.8vw;
    font-weight: normal;
    width: 20%;
    cursor: pointer;
    padding: 1%;
}
