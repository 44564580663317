.modalTitleContainer {
    display: flex;
    margin-bottom: 5%;
    color: var(--blue);
    justify-content: center;
    font-size: var(--h11);
    font-family: var(--font1);
    
}
.filterButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 55%;
}

.dropdown_type_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--font1);
}

.dropdown_type_div2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* border: solid red 1px; */
    height: 2vw;
    font-size: var(--h2);
    font-family: var(--font1);
    color: var(--cyan);
}
.timeline{
     //border: solid red 1px;
    font-family: var(--font1);
    
}

/* ----------------------tarjetas--------- */
.cardContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    font-family: var(--font1);
    //border: solid red 1px;
}
.cardHeader {
    display: flex;
    flex-direction: row;
    // border: solid rgb(6, 130, 10) 1px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.cardTitle {
    font-family: var(--font1);
    font-size: var(--h4);
    color: var(--cyan);
     //border: solid red 1px; 
    min-height: 2vw;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-top: 1%;
}
.range {
    display: flex;
    flex-direction: row;
    width: 30%;
   // padding-right: 2%;
    min-height: 2vw;
    align-items: center;
    font-size: var(--h6);
    justify-content: flex-end;
    /* border: solid rgb(255, 0, 0) 1px; */
    color: var(--blue);
    font-family: var(--font1);
}
.cardSubcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    //  border: solid rgb(4, 0, 255) 1px; 
}
.cardQrContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100%;
    // border: solid rgb(55, 255, 0) 1px;
}
.qrNumber {
    font-family: var(--font1);
    font-size: var(--h6);
    //color: var(--blue);
    position: relative;
    top: -1.4vw;
}
.cardInfoContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    // border: solid rgb(255, 0, 0) 1px; 
}

.temp {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: end;
    font-size: var(--h2);
    width: 100%;
    height: 30%;
    // border: solid rgb(255, 0, 0) 1px;
    font-family: var(--font1);
}
.lastCheck {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:flex-end;
    font-size: var(--h5);
    width: 100%;
    height: 20%;
    // border: solid rgb(255, 0, 0) 1px;
    color: var(--grey);
    font-family: var(--font1);
    position: relative;
    // left: 0.8vw;
     top: 0.8vw;
}

@keyframes checkpointAnimation {
    0% {
        color: #d8d8d8;
    }
    25% {
        color: transparent;
    }
    50% {
        color: #d8d8d8;
    }
    100% {
        color: transparent;
    }
}

.checkpoint_animation {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-size: var(--h5);
    width: 90%;
    height: 20%;
    // border: solid rgb(255, 0, 0) 1px;
    color: var(--grey);
    font-family: var(--font1);
    position: relative;
    left: 0.8vw;
    top: 0.8vw;
    animation-name: checkpointAnimation;
    animation-duration: 2s;
}

@keyframes checkpointAnimationTemp {
    0% {
        color: #d8d8d8;
    }
    25% {
        color: transparent;
    }
    50% {
        color: #d8d8d8;
    }
    100% {
        color: transparent;
    }
}

.checkpoint_animation_temp {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: end;
    font-size: var(--h1);
    width: 85%;
    height: 30%;
    /* border: solid rgb(255, 0, 0) 1px; */
    font-family: var(--font1);
    animation-name: checkpointAnimationTemp;
    animation-duration: 2s;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;

    /* border: solid rgb(255, 0, 0) 1px; */
    color: var(--blue);
    font-family: var(--font1);
    position: relative;
    top: 1.7vw;
}
.buttonChecksAndcontent {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--blue);
    font-size: var(--h4);
    //width: 50%;
    font-family: var(--font1);
}
