.container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.usersContainer{
    width: 100%;
    border-top: 0.15vw solid lightgrey;
    padding-top: 1vw;
}

.branchesContainer {
    width: 100%;
    border-top: 0.15vw solid lightgrey;
    padding-top: 1vw;
}


.eachData{
    display: flex;
    flex-direction: row;
    margin: 2%;
    width: 100%;
}

.branchesData{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.dataTitle{
    font-size: 1.3vw;
    color: #1B1464;
    margin-right: auto;
    margin-bottom: 1vw;
}

.editDataTempContainer{
    display: flex;
    width: 70%;
    margin-top: 1vw;
    justify-content: space-between;
}

.barcodesInput{
    margin-left: 1vw;
}

.editData{
    font-size: 1.1vw;
    margin-left: 1.5vw;
}

.createUserButton{
    border: 0.15vw solid #00ABC8;
    background-color: white;
    border-radius: 2vw;
    color: #00ABC8;
    font-size: 1.1vw;
    font-weight: normal;
    width: 15%;
    cursor: pointer;
    padding: 1%;
    margin-top: 1vw;
    margin-left: 44%;
}

.editButton{
    border: 0.15vw solid #00ABC8;
    background-color: white;
    border-radius: 2vw;
    color: #00ABC8;
    font-size: 1.1vw;
    font-weight: normal;
    width: 15%;
    cursor: pointer;
    margin-bottom: 2vw;
    padding: 1%;
}

.saveButton{
    border: 0.15vw solid #00ABC8;
    background-color: white;
    border-radius: 2vw;
    color: #00ABC8;
    font-size: 1.1vw;
    font-weight: normal;
    width: 30%;
    cursor: pointer;
    margin-bottom: 2vw;
    padding: 1%;
}

.cancelButton{
    border: 0.15vw solid #D60707;
    background-color: white;
    border-radius: 2vw;
    color: #D60707;
    font-size: 1.1vw;
    font-weight: normal;
    width: 30%;
    cursor: pointer;
    margin-bottom: 2vw;
    padding: 1%;
}

.buttonContainer{
    display: flex;
    width: 30%;
    justify-content: space-around;
}

.dataAlertParams{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 1vw;
    margin-top: 0.5vw;
}

.loading{
    margin-left: 48%;
    margin-top: 20%;
}

.container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.temp_alerts_container{
    width: 100%;
    height: 10vw;
}

.editDataTempContainer{
    display: flex;
    width: 102%;
    margin-top: 1vw;
    justify-content: space-between;
}

.editDataTemp{
    width: 30%;
}

.tempTitle{
    width: 100%;
    background-color: #D9F1F5;
    border: 0.03vw solid #BCE2EA;
    height: 2vw;
    font-size: 1.2vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accTitle{
    width: 120%;
    background-color: #D9F1F5;
    border: 0.03vw solid #BCE2EA;
    height: 2vw;
    font-size: 1.2vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dataTempContainer{
    width: 100.5%;
    display: flex;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}

.dataNumber{
    font-size: 1.3vw;
}

.eachDataTempTitle{
    font-size: 1.3vw;
}

.eachTempDataContainer{
    display: flex;
    width: 50%;
    justify-content: space-around;
    padding: 2.5%;
    border: 0.03vw solid #BCE2EA;
}

.acc_alerts_container{
    width: 45%;
    height: 2vw;
    margin-left: 4vw;
    margin-right: 1vw;
}

.type_alert_title {
    width: 100%;
    padding-top: 1vw;
    margin-right: auto;
    text-align: left;
    padding-left: 2vw;
    background-color: #BCE2EA;
    height: 2vw;
    font-size: 1.1vw;
}

.eachAccDataContainer{
    display: flex;
    width: 108%;
    justify-content: space-around;
    padding: 6%;
    border: 0.03vw solid #BCE2EA;
}

.select{
    font-family: 'Quattrocento Sans', sans-serif;
    font-size: 1vw;
    border-radius: 2vw;
    color: black;
    padding: 1%;
    margin-right: 1vw;
    margin: 1vw;
}