.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30vw;
    height: 35vw;
    padding-top: 1%;
    /*  border:solid red 1px; */
    margin-top: 0px;

}

.createTitle {
    margin-top: 2%;
    font-size: 1.7vw;

}

.eachSection {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 0.2vw;
    width: 100%;


}

.eachInput {
    width: 98%;
    height: 1vw;
    display: flex;
    border-radius: 2vw;
    text-align: start;
    padding: 1%;
    margin-top: 2%;

}

.eachLabel {
    text-align: start;
    color: #1B1464;
    font-size: 1.2vw;
}

.access_to_title {
    text-align: start;
    color: #1B1464;
    font-size: 1.2vw;
    margin-bottom: 3%;
}

.type_title {
    text-align: start;
    color: #1B1464;
    font-size: 1.2vw;
    margin-bottom: 3%;
}

.createButton {
    display: flex;
    border: 0.15vw solid #00ABC8;
    background-color: white;
    color: #00ABC8;
    border-radius: 2vw;
    font-size: 1vw;
    font-weight: normal;
    width: 40%;
    cursor: pointer;
    margin: 1% auto;
    padding: 1%;
    justify-content: center;

}

.select_default {

    font-family: 'Quattrocento Sans', sans-serif;
    padding: 2%;
    font-size: 1vw;
    border-radius: 2vw;
    width: 11vw
}


.type_and_accessTo {
    display: flex;
    /*  border:solid green 1px; */
    justify-content: space-between;
    margin-bottom: 5%;
}

.type {
    display: flex;

    /* border:solid blue 1px; */
}

.access_to {
    display: flex;
    /* border:solid orange 1px; */

}

@media (max-width:1550px) {
    .container {

        height: 50vw;
        padding-top: 1%;
        /*  border:solid red 1px; */


    }

    .access_to_list {

        font-size: 1.2vw;
    }

    .select_default {
        width: 11vw
    }
}

@media (max-width:800px) {
    .container {

        height: 50vw;
        padding-top: 1%;
        /*  border:solid red 1px; */


    }

    .access_to_list {

        font-size: 1.2vw;
    }

    .select_default {
        width: 11vw
    }
}