.btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: solid 3px rgb(58, 191, 239); */

    background-color: rgb(11, 2, 74);
    cursor: pointer;
    width: 100%;
    height: 2vw;
    position: relative;

    /* padding-left: 2%; */
    color: #fafafa;
    padding-left: 2%;
    padding-right: 2%;
}
.lastUpdate{
    color:#ccc;
    font-size: 0.7vw;
}
