.loadingSpinner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30%;
}

.loading {
    left: 50%;
    top: 50%;
    font-size: 36px;
    font-family: var(--font1);
    font-weight: normal;
    letter-spacing: 4.4px;
    text-transform: capitalize;
    position: absolute;
    overflow: hidden;
    transform: translate(-50%, -60%);
}

.loading:before {
    color: #73a9dfc7;
    content: attr(data-loading-text);
}

.loading:after {
    top: 0;
    left: 0;
    width: 0;
    opacity: 1;
    color: #00ABC8;
    overflow: hidden;
    position: absolute;
    content: attr(data-loading-text);
    animation: loading 5s infinite;
}

@keyframes loading {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}