.branchContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
   
    
    
}

.branches_data{
    font-family: var(--font1);
    font-weight: normal;
}
.editBranchButton{
    border: 0.15vw solid var(--cyan);
    background-color: var(--white);
    color: var(--cyan);
    border-radius: 2vw;
    font-size: var(--h5);
   // font-weight: normal;
    width: 10%;
    cursor: pointer;
    margin-bottom: 1vw;
    padding: 0.3%;
    margin-right: 1%;
}

.cancelButton{
    border: 0.15vw solid var(--red);
    background-color: var(--white);
    color: var(--red);
    border-radius: 2vw;
    font-size: var(--h4);
   // font-weight: normal;
    width: 10%;
    cursor: pointer;
    margin-bottom: 1vw;
    padding: 0.3%;
    margin-right: 1%;
}


.addBranchTitle{
    font-size: var(--h4);
    color: var(--blue);
    margin-bottom: 0.2vw;
}

.addBranchMainTitle{
    text-align: center;
    color: var(--blue);
    font-size: var(--h3);
}


.addBranchButton{
    border: 0.15vw solid var(--cyan);
    background-color: var(--white);
    color: var(--blue);
    border-radius: 2vw;
    font-size: var(--h5);
    font-weight: normal;
    width: 40%;
    cursor: pointer;
    margin-top: 0.8vw;
    margin-bottom: 0.5vw;
    padding: 2%;
}

.addBranchButton:disabled,
button[disabled]{
  border: 0.1vw solid var();
  background-color: #cccccc;
  color: #666666;
  cursor:not-allowed;
}

.addBranchContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 18vw;
    padding: 2%;
   
}
.editBranch{
    border: 0.15vw solid var(--cyan);
    background-color: white;
    color: var(--cyan);
    border-radius: 1.5vw;
    font-size: var(--h6);
    height: 1.5vw;
    width: 100%;
    cursor: pointer;
}
.deleteButton{
    border: 0.15vw solid var(--red);
    background-color: white;
    color: var(--red);
    border-radius: 1.5vw;
    font-size: var(--h6);
    height: 1.5vw;
    width: 100%;
    cursor: pointer; 
}
.branchesButtonContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pagination{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left: auto;
    margin-top: 1vw;
}

.addBranchSection{
    display: flex;
    flex-direction: column;
    margin-bottom: 0.8vw;
    width:100%;
   

}

.addBranchCheckbox{
    width: 1.2vw;
    height: 0.8vw;
    margin-top: 0.2vw;
}

.searchQrLabel{
    margin-right: 0.5vw;
}

.searchQrInput{
    border-radius: 2vw;
    border-color: #D9F1F5;
    padding: 2%;
    font-family: var(--font1) !important;
    font-size: var(--h4);
    width: 90%;
}

.inputContainer {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.addBranchData{
    width: 97%;
    height: 0.9vw;
    border-radius: 2vw;
    padding: 1.3%;
  
}

.addBranchText{
    font-size: var(--h4);
}
.select {
    font-family: var(--font1);
    padding: 2%;
    font-size: var(--h4);
    border-radius: 2vw;
}

@media (max-width:800px){
    .searchQrInput{
        padding: 3%;
        width: 100%;
        font-size: var(--h4);
    }

    .editBranchButton{
        font-size: var(--h5);
        width: 12%;
        padding: 0.2%;
        
        
        
    }

    .cancelButton{
        font-size: 2vw;
        width: 15%;
        padding: 2%;
    }
    
  }