.modalContent{
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    flex-wrap: wrap;
    justify-content: center;
   
    height: 75%;
   
}
.modalData{
    font-size: 2vw;
    margin-bottom: 0.3vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    
}