/* DEVICES -------------------------------------------------------------------------------- */

.devicesContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-top: 1vw;
    padding-bottom: 2vw;
}

.pagination{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left: auto;
    margin-top: 10px;
}

.searchQrLabel{
    margin-right: 0.6vw;
}

.searchQrInput{
    border-radius: 1vw;
    border-color: #D9F1F5;
    padding: 1%;
    font-family: var(--font1) !important;
    font-size: 1.1vw;
    width: 15vw;
    height: 1vw;
}

.inputContainer {
    margin-left: auto;
    margin-bottom: 1.5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.editDevicesButton{
    border: 0.15vw solid var(--cyan);
    background-color: var(--white);
    border-radius: 2vw;
    color: var(--cyan);
    font-size: var(--h6);
  // font-weight: normal;
    width: 5vw;
    cursor: pointer;
    margin-top: 2vw;
    margin-bottom: 1vw;
    padding: 2%;
}

.deleteDevicesButton{
    border: 0.15vw solid var(--red);
    background-color: var(--white);
    border-radius: 2vw;
    color: var(--red);
    font-size: var(--h6);
   // font-weight: normal;
    width: 5vw;
    cursor: pointer;
    margin-top: 2vw;
    margin-bottom: 1vw;
    padding: 2%;
}

.sensorsInputContainer{
    display: flex;
    width: 200px;
    flex-direction: column;
}

.contentButton{
    color: var(--blue);
    background-color: var(--transparent);
    border: none;
    font-size: var(--h4);
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
}

.select{
    font-family: var(--font1);
    padding: 3%;
    font-size: var(--h4);
    border-radius: 2vw;
}

.batteryLevelLabel{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.noDevices{
    font-size: 1.6vw;
    color: var(--blue);
}

.qrContainer{
    display: flex;
    justify-content: space-between;
    /* border: solid red 1px; */
}
.qr{
    margin-right: auto;
}
.qrDownloadButton{
   display: flex;  
    background: var(--transparent);
    border: none;
    cursor: pointer;
    z-index: 100;
   
   /* border: solid red 1px; */
   
   
}
.qrDownloadButton:disabled {
    display: flex;  
    background: var(--transparent);
    border: none;
    //cursor: pointer;
    z-index: 100;
   
  }
@media (max-width:800px){
    .searchQrInput{
        padding: 3%;
        font-size: 2vw;
        width: 25vw;
    }

    .editDevicesButton{
        font-size: 2vw;
        width: 12vw;
        padding: 3%;
    }
    
    .deleteDevicesButton{
        font-size: 2vw;
        width: 12vw;
        padding: 3%;
    }
  }