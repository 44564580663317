.container{
    /* border: solid rgb(0, 42, 255) 1px; */
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
   
}
.selectCalibrator_and_date{
    /* border: solid rgb(255, 0, 174) 1px; */
    width: 100%;
    height: 1%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    label{
        font-family: var(--font1) !important;
        font-size: small;
        
    };
    margin:auto;
    flex-grow: 1;
    
}

.tempAndCorrect_container{
    /* border: solid red 1px; */
    position: relative;
    top: -10px;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column; 
    margin: auto ;
    flex-grow: 1;
}
.pointTitle{
    /* border: solid red 1px; */
   display: flex;
   justify-content: center;
   align-items: center;
   font-family: var(--font1) !important;
    color: var(--blue);
    font-size: small;
    position: relative;
    left: -50%;
    
}
.tempAndCorrect1{
    /* border: solid rgb(0, 4, 255) 1px; */
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    label{
        font-family: var(--font1) !important;
        font-size: small;
    }
    margin-top: 10px;
}
.tempAndCorrect2{
    /* border: solid rgb(0, 4, 255) 1px; */
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    label{
        font-family: var(--font1) !important;
        font-size: small;
    }
    margin-top: 10px;
} 
.tempAndCorrect3{
    /* border: solid rgb(0, 4, 255) 1px; */
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    label{
        font-family: var(--font1) !important;
        font-size: small;
    }
    margin-top: 10px;
}  
.reportIdAndThermometer_container{
    position: relative;
    top:-90px;
    /* border: solid rgb(103, 103, 34) 1px; */
    width: 100%;
    height: 10%;
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    label{
        font-family: var(--font1) !important;
        font-size: small;
    }
   
}
.buttonContainer{
   
    display: flex;
    position: relative;
    top:-20px;
   height: 10%;

}
.title{
    font-family: var(--font1) !important;
    color: var(--blue);
    font-size: medium;
}