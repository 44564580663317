.cardContainer{
    width: 25%;
    padding: 1%;
    margin: 1%;
}

.title{
    font-size: 2vw;
    font-weight:bolder;
    margin-left: 1%;
    color: #1B1464;
}

.graphContainer{
    margin-top: 2vw;
    width: 65%;
    height: 10vw;
    margin-left: 15%;
    position: relative;
}

.textCentered{
    font-size: 2vw;
    color: #1B1464;
}

.textCenteredMin{
    font-size: 1.5vw;
    color: #1B1464;
}

.loading {
    margin-left: 40%;
    margin-top: 30%;
}

@media (max-width:800px){
    .cardContainer{
        width: 50%;
    }

    .graphContainer{
        margin-left: 2%;
        height: 24vw;
    }

    .textCentered{
        font-size: 5.8vw;
    }
    
    .textCenteredMin{
        font-size: 5.6vw;
    }

    .title{
        font-size: 4vw;
    }
}