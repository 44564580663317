.custom_accordion_summary {
    padding: 0;
    margin: 0 !important;
    

}
.tableTitle{
    display:flex;
    align-items: center;
    height: 2.5vw;
    padding-left: 2%;
    background-color: #a9c4e0;
    font-size: 1.2vw;
}
.tableTitleshiId_container{
    display:flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    right:0.8vw;
    bottom:0.4vw;
    height: 1.5vw;
    padding-left: 3%;
    padding-right: 3%;
    background-color: #a9c4e0;
    font-size: 1.2vw;
    width: 17.5vw;
}
.infoContainer{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    /* border: solid red 1px; */
    width: 18.3vw;
    height: 1.5vw;
    position: relative;
    right:0.8vw;
    margin-top: 0.1vw

}
.ship_id{
  display: flex;
  align-items: center;  
  /* border:solid green 1px; */
  width:50%;  
  padding-left: 3%;
  color:#fafafa;
  /* background-color: green; */
}
.ship_id_black{
    display: flex;
    align-items: center;  
    /* border:solid green 1px; */
    width:50%;  
    padding-left: 3%;
    color:black;
}
.ship_temp{
    display: flex;
    justify-content: center;
    align-items: center;  
    /* border:solid rgb(53, 0, 128) 1px; */
    width:30%

       
}
.alerts_icon {
    display: flex;
    width: 2vw;
}

