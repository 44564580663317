.barContainer {
  color: var(--blue);
  width: 40%;
  height: 92%;
  margin-right: 3%;
  margin-left: 1%;
  //margin-bottom: 2vw;
  //border:solid red 1px;
  padding: 1%;
}

.bar {
  margin-top: 0.4vw;
  margin-bottom: 0.4vw;
}

.title {
  font-family: var(--font1);
 // margin-top: 2vw;
  margin-bottom: 1vw;
  font-size: var(--h3);
 // font-weight: normal;
}

.barTitle {
  font-family: var(--font1);
  font-size: var(--h4);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75%; /* Full viewport height */
}

.noData {
  font-size: var(--h4);
  margin-top: 10%;
}

.infoContainer {
  height: 10vw;
//  border: solid red 1px;
  overflow-y: auto;
  padding-right: 25px;

}
/* Opcional: Estilos adicionales para la barra de desplazamiento */
.infoContainer::-webkit-scrollbar {
  width: 10px; /* Ancho de la barra de desplazamiento */
}

.infoContainer::-webkit-scrollbar-thumb {
  background-color: darkgrey; /* Color del pulgar de la barra de desplazamiento */
  border-radius: 10px; /* Radio de borde para el pulgar */
}

.infoContainer::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color de fondo de la pista */
}

.eachBranch {
  height: 3vw;
  margin-top: 0.3vw;
}

@media (max-width:800px){
  .barContainer{
    width: 40%;
    height: 35vw;
  }

  .title{
    font-family: var(--font1);
    font-size: 4vw;
    margin-bottom: 2vw;
  }

  .barTitle{
    font-size: 2.5vw;
}

  .eachBranch{
    margin-bottom: 4vw;
  }

  .noData{
    font-size: 3vw;
  }

}
//dash nuevo-----------------------------------------------
.barContainer2 {
  color: var(--blue);
  width: 40%;
  height: 92%;
  margin-right: 3%;
  margin-left: 1%;
  //margin-bottom: 2vw;
  //border:solid red 1px;
  //padding: 1%;
}

.bar2 {
  margin-top: 0.4vw;
  margin-bottom: 0.4vw;
}

.title2 {
 // margin-top: 2vw;
  margin-bottom: 1vw;
  font-size: var(--h3);
  //font-weight: normal;
  font-family: var(--font1);
}

.barTitle2 {
  font-size: var(--h4);
  font-family: var(--font1);
}

.loading2 {
  margin-left: 30%;
  margin-top: 30%;
}

.noData2 {
  font-size: var(--h4);
  margin-top: 10%;
}

.infoContainer2 {
  height: 10vw;
 //border: solid red 1px;
  overflow-y: auto;
  padding-right: 25px;

}
/* Opcional: Estilos adicionales para la barra de desplazamiento */
.infoContainer2::-webkit-scrollbar {
  width: 10px; /* Ancho de la barra de desplazamiento */
}

.infoContainer2::-webkit-scrollbar-thumb {
  background-color: darkgrey; /* Color del pulgar de la barra de desplazamiento */
  border-radius: 10px; /* Radio de borde para el pulgar */
}

.infoContainer2::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color de fondo de la pista */
}

.eachBranch2 {
  height: 3vw;
  margin-top: 0.3vw;
}